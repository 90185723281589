import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import { useDispatch, useSelector } from "react-redux";

function SlaveControl({ master, status, id }) {
  const [masterProps, setMasterProps] = useState({});
  const [min, setMin] = useState(500);
  const [step, setStep] = useState(100);
  const [chipCo2, setChipCo2] = useState(true);
  const [chipTemp, setChipTemp] = useState(false);
  const [chipInflow, setChipInflow] = useState(true);
  const [chipRecuperator, setChipRecuperator] = useState(false);
  const [chipOutflow, setChipOutflow] = useState(false);
  const [chipSync, setChipSync] = useState(true);
  const [chipAsync, setChipAsync] = useState(false);
  const [modeBase, setModeBase] = useState("sync");
  const redux = useSelector((state) => state.deviceModes);
  const slave = redux.find((itm) => itm.id == id);
  const reduxSlave = useSelector((state) => state.slaveParams);
  const dispatch = useDispatch();

  useEffect(() => {
    setMasterProps(redux.find((itm) => itm.id == master));
  }, [reduxSlave]);

  useEffect(() => {
    setMasterProps(redux.find((itm) => itm.id == master));
    if (!Array.isArray(slave.relation) && slave.relation.dependence.min_value) {
      setMin(slave.relation.dependence.min_value);
      dispatch({
        type: "EDIT_SLAVE_PARAMS_METEO_MIN",
        value: slave.relation.dependence.min_value,
      });
    } else {
      setMin("500");
      dispatch({
        type: "EDIT_SLAVE_PARAMS_METEO_MIN",
        value: "500",
      });
    }

    if (!Array.isArray(slave.relation) && slave.relation.dependence.step) {
      setStep(slave.relation.dependence.step);
      dispatch({
        type: "EDIT_SLAVE_PARAMS_METEO_STEP",
        value: slave.relation.dependence.step,
      });
    } else {
      setStep("100");
      dispatch({
        type: "EDIT_SLAVE_PARAMS_METEO_STEP",
        value: "100",
      });
    }

    if (!Array.isArray(slave.relation) && slave.relation.dependence.parametr) {
      switch (slave.relation.dependence.parametr) {
        case "co2":
          setChipCo2(true);
          setChipTemp(false);
          dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_PARAM", value: "co2" });
          break;
        case "temp":
          setChipTemp(true);
          setChipCo2(false);
          dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_PARAM", value: "temp" });
          break;
      }
    } else {
      setChipCo2(true);
      setChipTemp(false);
      dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_PARAM", value: "co2" });
    }

    if (
      !Array.isArray(slave.relation) &&
      (slave.relation.dependence.mode == "inflow" ||
        slave.relation.dependence.mode == "outflow" ||
        slave.relation.dependence.mode == "recuperator")
    ) {
      switch (slave.relation.dependence.mode) {
        case "inflow":
          // console.log('inflow');
          setChipInflow(true);
          setChipOutflow(false);
          setChipRecuperator(false);
          dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_MODE", value: "inflow" });
          break;

        case "outflow":
          // console.log('outflow');
          setChipInflow(false);
          setChipOutflow(true);
          setChipRecuperator(false);
          dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_MODE", value: "outflow" });
          break;

        case "recuperator":
          // console.log('rec');
          setChipInflow(false);
          setChipOutflow(false);
          setChipRecuperator(true);
          dispatch({
            type: "EDIT_SLAVE_PARAMS_METEO_MODE",
            value: "recuperator",
          });
          break;
      }
    } else {
      setChipInflow(true);
      setChipOutflow(false);
      setChipRecuperator(false);
      dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_MODE", value: "inflow" });
    }

    if (
      !Array.isArray(slave.relation) &&
      (slave.relation.dependence.mode == "sync" ||
        slave.relation.dependence.mode == "async")
    ) {
      // setModeBase(slave.relation.dependence.mode);
      // dispatch({
      //   type: 'EDIT_SLAVE_PARAMS_BASE',
      //   value: slave.relation.dependence.mode,
      // });
      switch (slave.relation.dependence.mode) {
        case "sync":
          setChipSync(true);
          setChipAsync(false);
          handleBase("sync");
          break;
        case "async":
          setChipSync(false);
          setChipAsync(true);
          handleBase("async");
          break;
      }
    } else {
      setChipSync(true);
      setChipAsync(false);
      handleBase("sync");
    }
  }, []);

  const handleBase = (val) => {
    setModeBase(val);
    dispatch({ type: "EDIT_SLAVE_PARAMS_BASE", value: val });
    // console.log(baseMode);
  };

  const handleMeteoMin = (val) => {
    dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_MIN", value: val });
  };

  const handleMeteoStep = (val) => {
    dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_STEP", value: val });
  };

  const handleMeteoParam = (val) => {
    dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_PARAM", value: val });
  };

  const handleMeteoMode = (val) => {
    dispatch({ type: "EDIT_SLAVE_PARAMS_METEO_MODE", value: val });
  };

  return (
    <div className="slave__form-dynamic">
      {masterProps.device_type_id == 1 ? (
        <div className="slave__chips">
          <Chip
            variant="outlined"
            disabled={!status}
            clickable={true}
            icon={chipSync ? <DoneIcon /> : <></>}
            color={chipSync ? "primary" : "default"}
            label="Синхронный"
            className="slave__chip"
            onClick={() => {
              setChipSync(true);
              setChipAsync(false);
              handleBase("sync");
            }}
          />
          <Chip
            variant="outlined"
            clickable={true}
            disabled={!status}
            icon={chipAsync ? <DoneIcon /> : <></>}
            color={chipAsync ? "primary" : "default"}
            label="Асинхронный"
            className="slave__chip"
            onClick={() => {
              setChipSync(false);
              setChipAsync(true);
              handleBase("async");
            }}
          />
        </div>
      ) : masterProps.device_type_id == 2 ? (
        <div className="slave__form-meteo">
          <div className="slave__form-textinputs">
            <TextField
              disabled={!status}
              label="Мин. знач."
              variant="outlined"
              className="slave__form-input"
              // defaultValue="500"
              defaultValue={min}
              type="text"
              size="small"
              onChange={(min) => {
                handleMeteoMin(min.target.value);
              }}
            />

            <TextField
              disabled={!status}
              label="Шаг значений"
              variant="outlined"
              className="slave__form-input"
              // defaultValue="100"
              defaultValue={step}
              type="text"
              size="small"
              onChange={(step) => {
                handleMeteoStep(step.target.value);
              }}
            />
          </div>

          <div className="slave__chips-heading">Параметр управления</div>
          <div className="slave__chips">
            <Chip
              variant="outlined"
              disabled={!status}
              clickable={true}
              icon={chipCo2 ? <DoneIcon /> : <></>}
              color={chipCo2 ? "primary" : "default"}
              label="CO2"
              className="slave__chip"
              onClick={() => {
                setChipCo2(true);
                setChipTemp(false);
                handleMeteoParam("co2");
              }}
            />
            <Chip
              variant="outlined"
              disabled={!status}
              clickable={true}
              icon={chipTemp ? <DoneIcon /> : <></>}
              color={chipTemp ? "primary" : "default"}
              label="Температура"
              className="slave__chip"
              onClick={() => {
                setChipCo2(false);
                setChipTemp(true);
                handleMeteoParam("temp");
              }}
            />
          </div>

          <div className="slave__chips-heading">Режим автоуправления</div>
          <div className="slave__chips">
            <Chip
              variant="outlined"
              disabled={!status}
              clickable={true}
              icon={chipInflow ? <DoneIcon /> : <></>}
              color={chipInflow ? "primary" : "default"}
              label="Приток"
              className="slave__chip"
              onClick={() => {
                setChipInflow(true);
                setChipOutflow(false);
                setChipRecuperator(false);
                handleMeteoMode("inflow");
              }}
            />
            <Chip
              variant="outlined"
              disabled={!status}
              clickable={true}
              icon={chipOutflow ? <DoneIcon /> : <></>}
              color={chipOutflow ? "primary" : "default"}
              label="Вытяжка"
              className="slave__chip"
              onClick={() => {
                setChipInflow(false);
                setChipOutflow(true);
                setChipRecuperator(false);
                handleMeteoMode("outflow");
              }}
            />

            <Chip
              variant="outlined"
              disabled={!status}
              clickable={true}
              icon={chipRecuperator ? <DoneIcon /> : <></>}
              color={chipRecuperator ? "primary" : "default"}
              label="Рекуперация"
              className="slave__chip"
              onClick={() => {
                setChipInflow(false);
                setChipOutflow(false);
                setChipRecuperator(true);
                handleMeteoMode("recuperator");
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SlaveControl;
