import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import "../styles/Form.scss";
// import "../styles/Signin.scss";

import logo from "../img/logo.png";

import { useDispatch } from "react-redux";
import { hide } from "../actions";

function ResetPass() {
  const elementRef = useRef(null);
  const [height, setHeight] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: "",
  });
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, [elementRef]);

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
  }, []);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleEmailChange = (val) => {
    setInputError(false);
    setData({
      ...data,
      resetEmail: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(data.resetEmail)) {
      // fetch(SiteUrl + "/wp-json/bdpwr/v1/reset-password/?" + url, {
      fetch(SiteUrl + "/forgot", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.resetEmail,
        }),
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          // console.log(json);
          if (json.code == 200) {
            history.push("/confirm", { email: data.resetEmail });
          } else {
            setInputError(true);
          }
        });
    } else {
      setInputError(true);
    }
  };

  return (
    <form
      className="form reset align-center"
      onSubmit={handleSubmit}
      ref={elementRef}
      // style={{ marginTop: "calc((90vh - " + height + "px) / 2)" }}
    >
      <div className="form__logo-container">
        <img className="form__logo" src={logo} alt="logo" />
      </div>
      <h2 className="form__heading">Сброс пароля</h2>
      <p className="form__text">
        Введите E-mail, на который зарегистрирован аккаунт
      </p>
      <TextField
        error={inputError}
        label={inputError ? "Неправильный e-mail или пароль" : "E-mail"}
        type="text"
        className="form__input"
        onChange={(email) => handleEmailChange(email.target.value)}
        variant="outlined"
      />

      <div className="form__bottom">
        <Link className="form__link link" to="/signin">
          Вход
        </Link>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="form__button"
          size="large"
        >
          Получить код
        </Button>
      </div>
    </form>
  );
}

export default ResetPass;
