import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import { hide } from "../actions";

import "../styles/Form.scss";
// import "../styles/Signin.scss";

import logo from "../img/logo.png";

function ConfirmRegistration() {
  const elementRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const resetEmail = location.state.email;
  const dispatch = useDispatch();
  const [codeError, setCodeError] = useState(false);
  const [buttonText, setButtonText] = useState("повторная отправка через 1:00");
  const [buttonState, setButtonState] = useState(true);

  const [data, setData] = useState({
    email: resetEmail,
    code: "",
    isCodeValid: false,
  });
  console.log(resetEmail);

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
    startTimer();
  }, []);

  const startTimer = () => {
    let i = 60;
    const timer = setInterval(() => {
      i--;
      setButtonText("повторная отправка через 0:" + (i > 9 ? i : "0" + i));
      if (i == 0) {
        clearInterval(timer);
        setButtonText("Отправить заново");
        setButtonState(false);
      }
    }, 1000);
  };

  const isFilled = (value) => {
    return value.length !== 0 ? true : false;
  };

  const handleCode = (val) => {
    setCodeError(false);
    if (isFilled(val)) {
      setData({
        ...data,
        code: val,
        isCodeValid: true,
      });
    } else {
      setCodeError(true);
    }
  };

  const handleResend = () => {
    setButtonState(true);

    fetch(SiteUrl + "/forgot", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
      }),
      method: "POST",
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.code == 200) {
          dispatch({
            type: "SHOW_SNACKBAR",
            value: "Код активации отправлен на электронную почту",
          });
          startTimer();
        } else {
          dispatch({
            type: "SHOW_SNACKBAR",
            value: "Ошибка! Попробуйте ещё раз",
          });
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.isCodeValid) {
      setCodeError(true);
      return;
    } else {
      fetch(SiteUrl + "/confirm", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          verify_code: data.code,
        }),
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.error == "22P02") {
            setCodeErr(true);
          } else {
            dispatch({
              type: "SHOW_SNACKBAR",
              value: "Регистрация завершена",
            });
            history.push("/signin");
          }
        });
    }
  };

  return (
    <form
      className="form confirm align-center"
      onSubmit={handleSubmit}
      ref={elementRef}
      // style={{ marginTop: "calc((90vh - " + height + "px) / 2)" }}
    >
      <div className="form__logo-container">
        <img className="form__logo" src={logo} alt="logo" />
      </div>
      <h2 className="form__heading">Подтверждение аккаунта</h2>
      <p className="form__text">
        {/* Введите код подтверждения и придумайте новый пароль */}
        На указанный E-mail отправлен код подтверждения, введите его
      </p>

      <TextField
        error={codeError}
        label={codeError ? "Неверный код подтверждения" : "Код подтверждения"}
        type="text"
        className="form__input"
        // size="small"
        onChange={(code) => handleCode(code.target.value)}
        variant="outlined"
      />
      <div
        className="btn-cont"
        style={{ width: "100%", textAlign: "left", margin: "9px 0" }}
      >
        <Button
          color="primary"
          disabled={buttonState}
          className="link"
          style={{ padding: 0 }}
          onClick={() => {
            handleResend();
          }}
        >
          {buttonText}
        </Button>
      </div>

      <div className="form__bottom">
        <Link className="form__link link" to="/signup">
          Назад
        </Link>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="form__button"
          size="large"
        >
          Подтвердить
        </Button>
      </div>
    </form>
  );
}

export default ConfirmRegistration;
