import React, { useRef, useEffect, useState, useContext } from "react";
import { AuthContext } from "../components/context";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import logo from "../img/logo.png";

import { useDispatch } from "react-redux";
import { hide } from "../actions";

import "../styles/Form.scss";
// import "../styles/Signin.scss";

function Signin() {
  const elementRef = useRef(null);
  const [height, setHeight] = useState(null);
  const [inputError, setInputError] = useState(false);
  const history = useHistory();
  const { signIn } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, [elementRef]);

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
  }, []);

  const [data, setData] = useState({
    username: "",
    password: "",
    check_textInputChange: false,
    // secureTextEntry: true,
    isValidUser: true,
    isValidPassword: true,
  });

  const handleUsername = (val) => {
    setInputError(false);
    if (val.trim().length >= 4) {
      setData({
        ...data,
        username: val,
        check_textInputChange: true,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        username: val,
        check_textInputChange: false,
        isValidUser: false,
      });
    }
  };

  const handlePassword = (val) => {
    setInputError(false);

    if (val.trim().length >= 5) {
      setData({
        ...data,
        password: val,
        isValidPassword: true,
      });
    } else {
      setData({
        ...data,
        password: val,
        isValidPassword: false,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let userAccess = "";
    let userToken = "";
    let userRefreshToken = "";

    fetch(SiteUrl + "/oauth/token", {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Basic RHRuYUFTNmpFS1gyc1lNT1NHUG9kd0dBQ0F1UnJoOlNic3R3Ym9taTFobXp6MU1xdmFMcjBBbU1MbmJmOA==",
      },
      method: "POST",
      body: JSON.stringify({
        grant_type: "password",
        username: data.username,
        password: data.password,
        client_id: "DtnaAS6jEKX2sYMOSGPodwGACAuRrh",
        client_secret: "Sbstwbomi1hmzz1MqvaLr0AmMLnbf8",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);

        let userName = data.username;
        let password = data.password;
        let token;
        if (json.access_token) {
          // console.log(json.access_token);
          token = json.access_token;
          fetch(SiteUrl + "/me?access_token=" + token, {
            method: "GET",
          })
            .then((response) => response.json())
            .then((jsonInner) => {
              if (jsonInner.content.verified == 1) {
                userToken = token;
                userRefreshToken = jsonInner.refresh_token;
                userAccess = {
                  userName,
                  password,
                  userToken,
                  userRefreshToken,
                };
                signIn(userAccess);
                history.push("/");
              } else {
                dispatch({
                  type: "SHOW_SNACKBAR",
                  value: "Аккаунт не подтверждён! Воспользуйтесь функцией восстановления пароля",
                });
                // нужен баннер
              }
            });
        } else {
          setInputError(true);
        }
      });
    // .catch((error) => console.error(error));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="signin form align-center"
      ref={elementRef}
      // style={{ marginTop: "calc((92vh - " + height + "px) / 2)" }}
    >
      <div className="form__logo-container">
        <img className="form__logo" src={logo} alt="logo" />
      </div>
      <h2 className="form__heading">Вход</h2>
      <p className="form__text">Введите свои E-mail и пароль</p>
      <TextField
        error={inputError}
        label={inputError ? "Неправильный e-mail или пароль" : "E-mail"}
        // helperText={inputError ? "Неправильный e-mail или пароль" : ""}
        className="form__input"
        type="text"
        onChange={(username) => handleUsername(username.target.value)}
        variant="outlined"
      />
      <TextField
        error={inputError}
        label={inputError ? "Неправильный e-mail или пароль" : "Пароль"}
        // helperText={inputError ? "Неправильный e-mail или пароль" : ""}
        className="form__input"
        type="password"
        onChange={(password) => handlePassword(password.target.value)}
        variant="outlined"
      />
      <Link className="form__link link" to="/reset">
        Забыли пароль?
      </Link>

      <div className="form__bottom">
        <Link className="form__link link" to="/signup">
          Регистрация
        </Link>

        <Button variant="contained" type="submit" color="primary" className="form__button" size="large">
          Войти
        </Button>
      </div>
    </form>
  );
}

export default Signin;
