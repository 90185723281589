import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Dialogs from "./Dialogs";

import AddIcon from "@material-ui/icons/Add";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import { useDispatch, useSelector } from "react-redux";
import { show } from "../actions";

import "../styles/Form.scss";
import "../styles/AddDevice.scss";

import smart from "../img/smart.png";
import atmosphere from "../img/atmosphere.png";
import windor from "../img/windor.png";

function AddDevice(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const dialogsRedux = useSelector((state) => state.dialogsReducer);

  const [types, setTypes] = useState(["Vakio Base", "Vakio Window"]);
  const [places, setPlaces] = useState([""]);
  const [open, setOpen] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [groupErr, setGroupErr] = useState(false);
  const [wifiErr, setWifiErr] = useState(false);
  const [passErr, setPassErr] = useState(false);

  const [createdPlace, setCreatedPlace] = useState("");

  const [data, setData] = useState({
    title: "",
    group: "",
    // device_type: types[0],
    login_wifi: "",
    pass_wifi: "",
    uuid: "test", // временно, на проде придёт с запросом
    id: 79,
    user_id: JSON.parse(localStorage.getItem("userData")).id, // сразу на прод
    mqtt_ip: "130.193.42.93",
    device_type_id: 1,
  });

  const [valid, setValid] = useState({
    devName: false,
    group: false,
    wifi: false,
    pass: false,
    type: false,
  });

  const d = new Date();
  const datestring =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    ", " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);

  useEffect(() => {
    dispatch({ type: "HEADING", value: "Новый прибор" });
    dispatch({ type: "SHOW_BAR" });
    const newPlaces = JSON.parse(localStorage.getItem("devices"))
      .map((item) => {
        return item.device_group;
      })
      .filter((val, ind, arr) => arr.indexOf(val) === ind)
      .filter((value) => {
        if (value != "") {
          return value;
        }
      });
    setPlaces(newPlaces);

    return () => {
      dispatch({ type: "CHANGE_TYPE", value: "" });
    };
  }, []);

  useEffect(() => {
    setCreatedPlace(dialogsRedux.groupValue);
    if (dialogsRedux.groupValue != "") {
      // setPlaces([...places, dialogsRedux.groupValue]);
      handlePlaceAdding(dialogsRedux.groupValue);
    }
  }, [dialogsRedux.groupValue]);

  useEffect(() => {
    setCreatedPlace(dialogsRedux.groupValue);
    if (dialogsRedux.typeValue != "") {
      setData({
        ...data,
        device_type_id: dialogsRedux.typeValue,
      });
      setValid({
        ...valid,
        type: true,
      });
    }
  }, [dialogsRedux.typeValue]);

  const handlePlaceAdding = (val) => {
    if (val) {
      setPlaces([...places, val]);
      setData({ ...data, group: val });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isFilled = (value) => {
    // тут нужно будет сделать проверку regex'ом
    return value.length !== 0 ? true : false;
  };

  const handleDeviceName = (val) => {
    setNameErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        title: val,
      });
      setValid({
        ...valid,
        devName: true,
      });
    } else {
      setNameErr(true);
    }
  };

  const handlePlace = (val) => {
    setGroupErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        group: val,
      });
      setValid({
        ...valid,
        group: true,
      });
    } else {
      setGroupErr(true);
    }
  };

  const handleWifi = (val) => {
    setWifiErr(false);
    if (isFilled(val)) {
      setData({ ...data, login_wifi: val });
      setValid({
        ...valid,
        wifi: true,
      });
    } else {
      setWifiErr(true);
    }
  };

  const handleWifiPass = (val) => {
    setPassErr(false);
    if (isFilled(val)) {
      setData({ ...data, pass_wifi: val });
      setValid({
        ...valid,
        pass: true,
      });
    } else {
      setPassErr(true);
    }
  };

  const handleSubmit = (e) => {
    // props.onSubmit(data);
    // ^^^ прокид данных на второй экран
    e.preventDefault();
    if (!valid.devName) {
      setNameErr(true);
      return;
    } else if (!valid.group) {
      setGroupErr(true);
      return;
    } else if (!valid.wifi) {
      setWifiErr(true);
      return;
      // } else if (!valid.pass) {
      //   setPassErr(true);
      //   return;
    } else if (!valid.type) {
      dispatch({
        type: "SHOW_SNACKBAR",
        value: "Выберите тип устройства!",
      });
      return;
    } else {
      localStorage.setItem("wifi", data.login_wifi);
      localStorage.setItem("wifiPass", data.pass_wifi);

      const send = JSON.stringify({
        device_name: data.title,
        device_group: data.group,
        device_type: data.device_type_id,
        user_date_time: datestring,
        state: "connecting",
        // device_type_id: data.device_type_id,
      });

      // console.log(send);
      // console.log(data);
      fetch(SiteUrl + "/devices", {
        // mode: "cors", // no-cors, *cors, same-origin
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: send,
        method: "POST",
      })
        // .then((res) => res.json())
        .then((response) => {
          if (response.status == 201) {
            return response.json();
          } else {
            console.log(response);
            // return null; тут надо сделать какой-то брейк
          }
        })
        .then((json) => {
          console.log(json);
          history.push("/confirm-dev", { toSend: json, local: data });
        });
    }
  };

  return (
    <>
      <form
        className="add-device form form--border-none align-center"
        onSubmit={(e) => {
          handleSubmit(e);
          // props.onSubmit(e.target.value);
        }}
      >
        <p className="form__text">Введите данные нового прибора</p>

        <List style={{ width: "108%", marginLeft: "-4%", paddingBottom: 0 }}>
          <ListItem
            button
            onClick={() => {
              dispatch({
                type: "SHOW_TYPE",
              });
            }}
          >
            <ListItemAvatar>
              {dialogsRedux.typeValue == "" ? (
                <Avatar style={{ backgroundColor: "#009BA4", width: 45, height: 45 }}>
                  <AddIcon />
                </Avatar>
              ) : (
                <div
                  style={{
                    backgroundColor: "#2B9DA53E",
                    width: 45,
                    height: 45,
                    borderRadius: 100,
                  }}
                >
                  <img
                    src={dialogsRedux.typeValue == 1 ? smart : dialogsRedux.typeValue == 2 ? atmosphere : dialogsRedux.typeValue == 3 ? windor : ""}
                    alt="devcie"
                    style={{ width: 35, height: 35, margin: 5 }}
                  />
                </div>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                dialogsRedux.typeValue == 1
                  ? "Vakio Base Smart"
                  : dialogsRedux.typeValue == 2
                  ? "Vakio Atmosphere"
                  : dialogsRedux.typeValue == 3
                  ? "Vakio Windor"
                  : "Выберите тип устройства"
              }
              secondary={dialogsRedux.typeValue == 1 ? "Рекуператор" : dialogsRedux.typeValue == 2 ? "Метеостанция" : dialogsRedux.typeValue == 3 ? "Автоклапан" : ""}
            />
            <ListItemSecondaryAction>
              <SwapHorizIcon style={{ marginTop: 10 }} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <TextField
          error={nameErr}
          label={!nameErr ? "Название прибора" : "Поле не должно быть пустым"}
          // label="Название прибора"
          variant="outlined"
          className="form__input"
          type="text"
          size="small"
          onChange={(deviceName) => {
            handleDeviceName(deviceName.target.value);
          }}
        />

        <div className="add-device__group">
          <FormControl variant="outlined" className="add-device__select" size="small" error={groupErr}>
            <InputLabel id="place">{!groupErr ? "Расположение" : "Поле не должно быть пустым"}</InputLabel>
            <Select
              labelId="place"
              id="place"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              defaultValue={""} // выбор вручную
              // value={places[places.length - 1]} // контролируется реактом, выбор через код
              onChange={(place) => {
                handlePlace(place.target.value);
              }}
              label={!groupErr ? "Расположение" : "Поле не должно быть пустым"}
            >
              <MenuItem value="Спальня">Спальня</MenuItem>
              <MenuItem value="Детская">Детская</MenuItem>
              {places.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <AddIcon
          className="add-device__icon"
          onClick={() => handlePlaceAdding()}
        /> */}
          <Button
            color="primary"
            size="small"
            className="add-device__icon"
            // onClick={() => setDialogOpen(true)}
            onClick={() => {
              dispatch({
                type: "SHOW_GROUP",
              });
            }}
          >
            + Или добавьте новую
          </Button>
        </div>

        <TextField
          error={wifiErr}
          label={!wifiErr ? "Название вашей Wi-Fi сети" : "Поле не должно быть пустым"}
          // label="Название вашей Wi-Fi сети"
          variant="outlined"
          className="form__input"
          type="text"
          size="small"
          onChange={(wifi) => {
            handleWifi(wifi.target.value);
          }}
        />

        <TextField
          error={passErr}
          label={!passErr ? "Пароль от вашей Wi-Fi сети" : "Поле не должно быть пустым"}
          // label="Пароль от вашей Wi-Fi сети"
          variant="outlined"
          className="form__input"
          type="text"
          size="small"
          onChange={(pass) => {
            handleWifiPass(pass.target.value);
          }}
        />

        <div className="add-device__button-container">
          <Button variant="contained" type="submit" color="primary" className="form__button add-device__button" size="large">
            Далее
          </Button>
        </div>
      </form>
      <Dialogs />
    </>
  );
}

export default AddDevice;
