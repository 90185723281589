import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SignalCellularOffIcon from "@material-ui/icons/SignalCellularOff";
import WifiIcon from "@material-ui/icons/Wifi";
import DoneIcon from "@material-ui/icons/Done";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useDispatch } from "react-redux";
import { show } from "../actions";

function ConfirmDevice() {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useLocation().state.toSend;
  const local = useLocation().state.local;
  // console.log("data: ", data);
  // console.log("local: ", local);

  const d = new Date();
  const datestring =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    ", " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);

  const [isConnecting, setIsConnecting] = useState(false);
  const [connType, setConnType] = useState("checkStatus");
  const [connReTry, setConnReTry] = useState(false);
  const [connCount, setConnCount] = useState(0);
  const [connStatus, setConnStatus] = useState("Идет подключение...");

  const url = new URLSearchParams({
    user_id: data.content.user_id,
    device_id: data.content.id,
    uuid: data.content.uuid,
    wifi_ssid: local.login_wifi,
    wifi_pass: local.pass_wifi,
    mqtt_ip: local.mqtt_ip,
    server_ip: local.mqtt_ip,
    time: datestring,
  }).toString();
  // console.log(url);

  useEffect(() => {
    // dispatch(show());
    dispatch({ type: "HEADING", value: "Подключить" });
    dispatch({ type: "SHOW_BAR" });
  }, []);

  const handleConnect = () => {
    try {
      let delay = 5000;
      let count = 0;
      var timer = setTimeout(function checkStatus() {
        fetch(SiteUrl + "/devices/" + data.content.id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          method: "GET",
        })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              count++;
              return response.json();
            } else {
              timer = setTimeout(checkStatus, delay);
            }
          })
          .then((json) => {
            console.log(json);
            if (json) {
              if (json.content.verified == "1") {
                clearTimeout(timer);
                setIsConnecting(false);
                if (responce.status === 200) {
                  dispatch({
                    type: "SHOW_SNACKBAR",
                    value: "Подключение успешно! Подождите несколько секунд...",
                  });
                  history.push("/");

                  console.log("Успешно checkConnect");
                }
              } else {
                if (count >= 20) {
                  clearTimeout(timer);
                  setIsConnecting(false);
                  dispatch({
                    type: "SHOW_SNACKBAR",
                    value: "Ошибка! Попробуйте еще раз",
                  });
                } else {
                  timer = setTimeout(checkStatus, delay);
                }
              }
            }
          })
          .catch((err) => {
            if (count > 0) {
              count--;
            }
            timer = setTimeout(checkStatus, delay);
          });
        console.log(count);
      }, delay);
    } catch {
      timer = setTimeout(checkStatus, delay);
    }
  };

  // useEffect(() => {
  //   if (!isConnecting) return;
  //   if (connType != "checkStatus") return;

  //   const checkStatus = setTimeout(() => {
  //     console.log("http://192.168.0.1/newdataserver?" + new URLSearchParams(url).toString());
  //     setConnStatus("Подключаемся к устройству...");
  //     console.log("Начали checkStatus");
  //     fetch("http://192.168.0.1/newdataserver?" + new URLSearchParams(url).toString(), {
  //       method: "GET",
  //     })
  //       //.then((response) => response.json())
  //       .then((response) => {
  //         setConnCount(connCount + 1);
  //         console.log("Получен responce checkStatus");
  //         if (response.status === 200) {
  //           setConnCount(0);
  //           setConnStatus("Подключение успешно, проверяем сеть");
  //           setConnType("checkConnect");
  //           console.log("Успешно responce checkStatus");
  //           //timerConnect = setTimeout(checkConnect, 500);;
  //         } else {
  //           if (connCount > 5) {
  //             setConnCount(0);
  //             setIsConnecting(false);
  //             dispatch({
  //               type: "SHOW_SNACKBAR",
  //               value:
  //                 // 'Подключение к устройству не удалось, проверьте подключение к сети Wi-Fi устройства или попробуйте ДРУГОЙ СПОСОБ',
  //                 "Подключение к устройству не удалось, проверьте подключен ли смартфон к сети устройства",
  //             });
  //             console.log("Неуспешно responce checkStatus -все");
  //           } else {
  //             setConnStatus("Подключение неуспешно, пробуем еще");
  //             console.log("Неуспешно responce checkStatus");
  //             setConnReTry(!connReTry);
  //             //checkStatus();
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         setConnCount(connCount + 1);
  //         console.log("disconnect error", err);
  //         if (connCount > 5) {
  //           setConnCount(0);
  //           setIsConnecting(false);
  //           dispatch({
  //             type: "SHOW_SNACKBAR",
  //             value:
  //               // 'Подключение к устройству не удалось, проверьте подключение к сети Wi-Fi устройства или попробуйте ДРУГОЙ СПОСОБ',
  //               "Подключение к устройству не удалось, проверьте подключен ли смартфон к сети устройства",
  //           });
  //           console.log(err);
  //         } else {
  //           // dispatch({
  //           //   type: 'SHOW_SNACKBAR',
  //           //   value: 'Подключение неуспешно, пробуем еще',
  //           // });
  //           setConnStatus("Подключение неуспешно, пробуем еще");
  //           console.log("Пробуем еще checkStatus");
  //           setConnReTry(!connReTry);
  //           //checkStatus();
  //         }
  //       });
  //   }, 1000);

  //   return () => clearTimeout(checkStatus);
  // }, [isConnecting, connType, connReTry]);

  // useEffect(() => {
  //   if (connType != "checkConnect") return;
  //   if (!isConnecting) return;

  //   const checkConnect = setTimeout(() => {
  //     setConnStatus("Проверяем сеть...");
  //     console.log("Начали checkConnect");
  //     fetch("http://192.168.0.1/status", {
  //       method: "POST",
  //     })
  //       // .then((response) => response.json())
  //       .then((responce) => {
  //         setConnCount(connCount + 1);
  //         console.log(responce);
  //         console.log("Получен responce checkConnect");
  //         if (responce.status === 200) {
  //           dispatch({
  //             type: "SHOW_SNACKBAR",
  //             value: "Подключение успешно! Подождите несколько секунд...",
  //           });
  //           history.push("/");

  //           console.log("Успешно checkConnect");
  //         } else {
  //           if (connCount > 5) {
  //             setConnCount(0);
  //             setIsConnecting(false);
  //             dispatch({
  //               type: "SHOW_SNACKBAR",
  //               value: "Сеть Wi-Fi не подтверждена, вернитесь назад и и проверьте название и пароль Wi-Fi",
  //             });
  //             console.log("Неуспешно checkConnect");
  //           } else {
  //             setConnStatus("Сеть Wi-Fi не подтверждена, пробуем еще");
  //             setConnReTry(!connReTry);
  //             //checkConnect();
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         setConnCount(connCount + 1);
  //         if (connCount > 5) {
  //           setConnCount(0);
  //           setIsConnecting(false);
  //           dispatch({
  //             type: "SHOW_SNACKBAR",
  //             value: "Сеть Wi-Fi не подтверждена, вернитесь назад и и проверьте название и пароль Wi-Fi",
  //           });
  //           console.log(err);
  //         } else {
  //           // dispatch({
  //           //   type: 'SHOW_SNACKBAR',
  //           //   value: 'Сеть Wi-Fi не подтверждена, пробуем еще',
  //           // });
  //           setConnStatus("Сеть Wi-Fi не подтверждена, пробуем еще");
  //           console.log("Пробуем еще checkConnect");
  //           setConnReTry(!connReTry);
  //           //checkConnect();
  //         }
  //       });
  //   }, 3000);

  //   return () => clearTimeout(checkConnect);
  // }, [isConnecting, connType, connReTry]);

  // useEffect(() => {
  //   if (connType != "checkStatusSecondary") return;
  //   if (!isConnecting) return;
  //   const checkStatusSecondary = setTimeout(() => {
  //     setTimeout(() => {
  //       setConnStatus("Подключитесь к вашей Wi-Fi сети");
  //     }, 10000);
  //     new Promise((resolve) => {
  //       resolve(localStorage.getItem("userToken"));
  //     }).then((token) => {
  //       // fetch(SiteUrl + '/devices/' + data.toSend.content.id, {
  //       fetch(SiteUrl + "/devices/" + data.content.id, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //         method: "GET",
  //       })
  //         .then((response) => {
  //           console.log(response);
  //           setConnCount(connCount + 1);
  //           if (response.status === 200) {
  //             return response.json();
  //           } else {
  //             setConnReTry(!connReTry);
  //           }
  //         })
  //         .then((json) => {
  //           console.log(json);
  //           if (json) {
  //             if (json.content.verified == "1") {
  //               history.push("/");
  //             } else {
  //               if (connCount >= 10) {
  //                 setConnCount(0);
  //                 setIsConnecting(false);
  //                 dispatch({
  //                   type: "SHOW_SNACKBAR",
  //                   value: 'Устройство не подключено. Нажмите "Подключить" еще раз',
  //                 });
  //               } else {
  //                 setConnReTry(!connReTry);
  //               }
  //             }
  //           }
  //         })
  //         .catch((err) => {
  //           if (connCount > 0) {
  //             setConnCount(connCount - 1);
  //           }
  //           setConnReTry(!connReTry);
  //         });
  //     });
  //   }, 5000);

  //   return () => clearTimeout(checkStatusSecondary);
  // }, [isConnecting, connType, connReTry]);

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  if (isConnecting) {
    return (
      <div>
        <Backdrop open={true} style={{ background: "transparent" }}>
          <CircularProgress color="primary" />
          <div
            style={{
              marginLeft: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Идет подключение...
          </div>
        </Backdrop>
      </div>
    );
  } else {
    return (
      <div className="confirm-device" style={styles.centerNoMargin}>
        <List dense={false} style={{ margin: "20px 3% 20px 3%" }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#009BA4" }}>
                <SignalCellularOffIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Отключите передачу мобильных данных вашей сети" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#009BA4" }}>
                <WifiIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Выберите Wi-fi устройства и подключитесь к ней" secondary="Пароль по умолчанию Vakio0000" />
          </ListItem>

          {local.device_type_id == 1 ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#009BA4" }}>
                  <TouchAppIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText primary="Если Wi-Fi прибора отсутствует, зажмите левую кнопку и дождитесь отклика" />
            </ListItem>
          ) : local.device_type_id == 2 || local.device_type_id == 3 ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#009BA4" }}>
                  <PowerSettingsNewIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText primary="Выключите прибор из сети и включите его заново" />
            </ListItem>
          ) : (
            ""
          )}

          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#009BA4" }}>
                <DoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='Нажмите кнопку "Подключить", дождитесь загрузки страницы и вернитесь в приложение' secondary="Подключение может занять до 2-х минут" />
          </ListItem>
        </List>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          size="large"
          style={{ marginBottom: "auto" }}
          // style={styles.button}
          onClick={() => {
            handleConnect();
            // setIsConnecting(true);
            window.open("http://192.168.0.1/newdataserver?" + url);
          }} //}
        >
          Подключить
        </Button>
      </div>
    );
  }
}

export default ConfirmDevice;
