const defaultState = {
  groupVisibility: false,
  groupValue: "",
  typeVisibility: false,
  typeValue: "",
};

function dialogsReducer(state = defaultState, action) {
  switch (action.type) {
    case "SHOW_GROUP":
      return {
        ...state,
        groupVisibility: true,
      };
    case "HIDE_GROUP":
      return {
        ...state,
        groupVisibility: false,
      };
    case "ADD_GROUP":
      return {
        ...state,
        groupValue: action.value,
      };
    case "SHOW_TYPE":
      return {
        ...state,
        typeVisibility: true,
      };
    case "HIDE_TYPE":
      return {
        ...state,
        typeVisibility: false,
      };
    case "CHANGE_TYPE":
      return {
        ...state,
        typeValue: action.value,
      };
    default:
      return state;
  }
}

export default dialogsReducer;
