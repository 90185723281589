import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../actions";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import { Paper } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import LockIcon from "@material-ui/icons/Lock";
import { withStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTint } from "@fortawesome/free-solid-svg-icons";
import { faSmog } from "@fortawesome/free-solid-svg-icons";
import { faThermometerHalf } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import LoopIcon from "@material-ui/icons/Loop";

import humiditySvg from "../img/humidity.svg";
import temperatureSvg from "../img/temperature.svg";
import coSvg from "../img/co-small.svg";
import smart from "../img/smart.png";
import atmosphere from "../img/atmosphere.png";
import windor from "../img/windor.png";
import waterfall from "../img/waterfall.png";

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "auto",
    bottom: -10,
    right: 14,
  },
}))(Badge);

function HomeListItem({ itm, devices }) {
  const history = useHistory();
  const redux = useSelector((state) => state.deviceModes);
  const dispatch = useDispatch();
  const [invisible, setInvisible] = useState(itm.relation.on_off_dependence == "on" ? false : true);
  const [toggleDisabled, setToggleDisabled] = useState(itm.relation.on_off_dependence == "on" ? true : false);
  const [disableJet, setDisableJet] = useState([]);

  useEffect(() => {
    if (itm.state == 0) {
      setToggleDisabled(true);
    }
  }, []);

  const masterName =
    !Array.isArray(itm.relation) && itm.relation.on_off_dependence === "on" ? redux.find((inner) => inner.id == itm.relation?.dependence?.device_id_master)?.device_name : false;

  const translateName = (input) => {
    switch (input) {
      case "recuperator": {
        return "Рекуперация";
      }
      case "winter": {
        return "Рекуперация";
      }
      case "outflow": {
        return "Вытяжка";
      }
      case "inflow": {
        return "Приток";
      }
      case "inflow_max": {
        return "Макс. приток";
      }
      case "outflow_max": {
        return "Макс. отток";
      }
      case "night": {
        return "Ночь";
      }
      case "manual": {
        return "Ручное";
      }
      case "auto": {
        return "Авто";
      }
      case "super_auto": {
        return "Авто +";
      }
    }
  };

  const handleModeChange = (id, val) => {
    const activeDevice = redux.find((item) => item.id == id);
    activeDevice.capabilities.on_off = val ? "on" : "off";
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    // dispatch({ type: "SET_DEVICES", value: editedDevices });
    // localStorage.setItem("devices", JSON.stringify(editedDevices));
    dispatch(setDevices(editedDevices));
    // console.log(redux);
    fetch(SiteUrl + "/devices/" + id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "on_off",
            value: val ? "on" : "off",
          },
        ],
      }),
      method: "PUT",
    });
    // .then((res) => res.json())
    // .then((json) => console.log(json));
  };

  // const headelResetJets = () => {
  //   setDisableJet([])
  // };
  // var secondary;
  // var secondaryActive;
  itm.capabilities.waterfall_value
    ? itm.capabilities.waterfall_value
        .map((jet) => {
          return jet.jet_capabilities.jet_numbers;
        })
        .map((item) => {
          return item.split(",").map((item) => {
            disableJet.push(item);
          });
        })
    : null;

  // console.log("disableJet", disableJet);
  const secondary =
    itm.verified == 1
      ? masterName && itm.relation.on_off_dependence != "off"
        ? "Управляется: " + masterName
        : itm.device_type_id == 1 || itm.device_type_id == 3
        ? [
            translateName(itm.capabilities.mode),
            itm.capabilities.mode == "inflow_max" || itm.capabilities.mode == "outflow_max" || itm.capabilities.mode == "auto" || itm.capabilities.mode == "super_auto"
              ? ""
              : ", Скорость: " + itm.capabilities.speed,
          ]
        : itm.device_type_id == 2
        ? [
            <img
              src={humiditySvg}
              alt="humidity"
              key="1"
              style={{
                height: 15,
                verticalAlign: "text-top",
                marginRight: 1,
                marginLeft: 3,
              }}
            />,
            itm.properties.humidity ? itm.properties.humidity + "%" : 11 + "%",
            // <FontAwesomeIcon
            //   className="home__meteo-icon"
            //   icon={faSmog}
            //   key="2"
            // />,
            <img
              src={coSvg}
              alt="co2"
              key="2"
              style={{
                height: 15,
                verticalAlign: "text-top",
                marginRight: 3,
                marginLeft: 10,
              }}
            />,
            itm.properties.co2_level ? itm.properties.co2_level + "ppm" : 111 + "ppm",
            // <FontAwesomeIcon
            //   className="home__meteo-icon"
            //   icon={faThermometerHalf}
            //   key="3"
            // />,
            <img
              src={temperatureSvg}
              alt="temperature"
              key="3"
              style={{
                height: 15,
                verticalAlign: "text-top",
                marginRight: 3,
                marginLeft: 10,
              }}
            />,
            itm.properties.temperature ? itm.properties.temperature + "°C" : 11 + "°C",
          ]
        : itm.device_type_id == 4
        ? [
            "Активные форсунки: ",
            disableJet.length
              ? disableJet
                  .sort((a, b) => a - b)
                  .filter((val, ind, arr) => arr.indexOf(val) === ind)
                  .filter((value) => {
                    if (value != "") {
                      return value;
                    }
                  })
                  .join(", ")
              : "Нет активных форсунок",
          ]
        : ""
      : "Ожидается подключение...";

  return (
    <Paper
      elevation={0}
      className="home__item"
      classes={{
        rounded: "home__border-radius",
      }}
    >
      <ListItem
        onClick={() => {
          console.log(itm);
          if (itm.verified == 1) {
            history.push(`/device-${itm.id}`, {
              pickedDevice: itm,
              allDevices: devices,
            });
          } else if (itm.verified == 0) {
            history.push("/edit-dev", {
              state: itm,
            });
          }
        }}
      >
        <ListItemIcon>
          <StyledBadge className="home__badge" invisible={itm.verified == 0 ? true : invisible} badgeContent={<FontAwesomeIcon icon={faLock} />} color="secondary">
            {itm.verified == 1 ? (
              // <img
              //   className="home__image"
              //   src={
              //     itm.device_type == null
              //       ? "https://vakio.ru/wp-content/uploads/2020/02/vakio-base-plus-order-300x300.jpg"
              //       : itm.device_type.image
              //       ? itm.device_type.image
              //       : "https://vakio.ru/wp-content/uploads/2020/02/vakio-base-plus-order-300x300.jpg"
              //   }
              //   alt={itm.id}
              // />
              <div
                style={{
                  backgroundColor: itm.state == 1 ? "#2B9DA53E" : "#D9D9D9",
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                }}
              >
                <img
                  src={itm.device_type_id == 1 ? smart : itm.device_type_id == 2 ? atmosphere : itm.device_type_id == 3 ? windor : itm.device_type_id == 4 ? waterfall : ""}
                  alt="devcie"
                  className="home__image"
                  style={{ width: 40, height: 40, margin: 5 }}
                />
              </div>
            ) : (
              <LoopIcon color="action" fontSize="large" style={{ width: 40, height: 40, margin: 5 }} />
            )}
          </StyledBadge>
        </ListItemIcon>
        <ListItemText primary={itm.device_name} secondary={itm.state == 1 ? secondary : "Не в сети"} />
        {itm.verified == 1 && (itm.device_type_id == 1 || itm.device_type_id == 3) ? (
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              color="primary"
              disabled={toggleDisabled}
              checked={itm.capabilities.on_off == "on" ? true : false}
              // checked={true}
              onChange={(sw) => {
                handleModeChange(itm.id, sw.target.checked);
              }}
            />
          </ListItemSecondaryAction>
        ) : (
          ""
        )}
      </ListItem>
    </Paper>
  );
}

export default HomeListItem;
