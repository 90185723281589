import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import WaterfallGroup from './WaterfallGroup';
import JetSettings from './JetSettings';
import { createStore } from "redux";

import {
    Tooltip,
    SwipeableDrawer,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Switch,
    Divider,
    Typography,
    CircularProgress,
    Fab
} from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import { setHeading } from "../actions";

import { setDevices } from "../actions";
import "../styles/WaterfallScreen.scss";

import { useDispatch, useSelector } from "react-redux";

const GROUP_PLACEHOLDER = "Выберите расположение форсунок"


const WaterfallScreen = ({ switchChange, device, allDevices }) => {

const DEFAULT_JET = {
  jet_group_name: GROUP_PLACEHOLDER,
  jet_capabilities: {
    jet_manual: 1,
    jet_numbers: ""
  },
  jet_on_off: "on",
  jet_relation: {
    jet_on_off_dependence : "off"
  }  
}

    // const redux = useSelector((state) => state.deviceModes);
// const device = useLocation().state.pickedDevice;
const history = useHistory();
const groupSet = new Set();
const groupArr = [];
const parentDevices = allDevices.filter((device) => {
  return (device.device_type_id == 2);
})

const [actualAllDevices, setActualAllDevices] = useState(allDevices);
const [activeDevice, setActiveDevice] = useState({device});
const [jetsList, setJetsList] = useState([]);
const [showPreloader, setShowPreloader] = useState(false)
const [showJetSettings, setShowJetSettings] = useState(false)
const [activeJetSettings, setActiveJetSettings] = useState(DEFAULT_JET)

const hendleSetActiveJetSettings = (val) => {
  setActiveJetSettings(val)
}

    const useStyles = makeStyles((theme) => ({
        drawerPaper: {
            maxWidth: `420px`,
            margin: `0 auto`,
            left: `0`
        },
        drawerOverlay: {
            maxWidth: `420px`,
            margin: `0 auto`,
        }
    }));

    const classes = useStyles();

    const [tooltipVis, setTooltipVis] = useState(false);
    const [bottomSheet, setBottomSheet] = useState(false);
    const [status, setStatus] = useState(
        device.capabilities.on_off == "on" ? true : false
    );
    const [disabled, setDisabled] = useState(
        device.relation.on_off_dependence == "on" ? true : false
    );


    useEffect(() => {
      fetchDeviceData();
      }, []);

    const fetchDeviceData = () => {
        setShowPreloader(true)
       fetch(SiteUrl + '/devices', {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
              },
            method: 'GET',
          })
            .then((res) => res.json())
            .then((json) => {
              setActualAllDevices(json.content)
                setStatus(
                  json.content.filter((item)=>{return item.id == device.id})[0].capabilities.on_off == 'on'
                    ? true
                    : json.content.capabilities.on_off == 'off'
                    ? false
                    : false,
                );
                setActiveDevice(json.content.filter((item)=>{return item.id == device.id})[0]);
                setJetsList(json.content.filter((item)=>{return item.id == device.id})[0].capabilities.waterfall_value)
                setShowPreloader(false)

            });
      };
jetsList.forEach((val, key) => {
  groupSet.add(val.jet_group_name)
})
groupSet.forEach(val=>{groupArr.push(val)})

// console.log(jetsList.length);
// console.log(device);  
// console.log(typeof(groupArr));
    return (
       <div className="WaterfallScreen__content">
          {showPreloader ? (
            <div className="WaterfallScreen__Preloader">
              <CircularProgress />
            </div>
          ) : (
            <div> 
           <div className="WaterfallScreen__status">
           <div htmlFor="status" className="WaterfallScreen__label">
             {device.device_name}
           </div>

           <Tooltip
             open={tooltipVis}
             placement="left"
             onClose={() => setTooltipVis(false)}
             onOpen={() => setTooltipVis(true)}
             title="Настроить"
             disableFocusListener
             disableHoverListener
             disableTouchListener
             arrow
           >
             <MoreVertIcon
               className="WaterfallScreen__menu-dots"
               onClick={() => setBottomSheet(true)}
               style={{ marginRight: 10 }}
             />
           </Tooltip>
           <Switch
              color="primary"
              checked={status}
              disabled={disabled}
              className="switch__component"
              onChange={() => {
                  switchChange(),
                  setStatus(!status);
            }}
            />
        </div>
          <Divider />
          <div className="WaterfallScreen__jets-list">
              {jetsList.length != 0 ? (
                groupArr.sort((a, b) => {
                  return a > b ? 1 : a == b ? 0 : a < b ? -1 : 0;
                }).map((item, i) => {
                  return(
                    <WaterfallGroup 
                      key={i}
                      groupName={item}
                      device={jetsList}
                      parentDevices={parentDevices}
                      pageId={activeDevice.id}
                      enabled={status}
                      closeSettings={setShowJetSettings}
                      fetch={fetchDeviceData}
                      setActiveJetSettings={hendleSetActiveJetSettings}
                      showJetSettings={showJetSettings}
                      activeJetSettings={activeJetSettings}
                    />
                  )
                })
              )
                : <Typography className="WaterfallScreen__GroupTitle">
                    Нет форсунок
                  </Typography> }
          </div>
          <Fab
            color="primary"
            aria-label="add"
            className="WaterfallScreen__FloatBatton"
            onClick={() => {
              hendleSetActiveJetSettings(DEFAULT_JET)
              setShowJetSettings(!showJetSettings)
            }}
          >
            <AddIcon />
          </Fab>
          <Drawer
            classes={{
              root: classes.drawerOverlay,
              paper: classes.drawerPaper,
             }}
             BackdropProps={{
              classes: {
                root: "WaterfallScreen__popup-overlay",
            }
              }}
            open={showJetSettings}
            anchor="bottom"
            onClose={() => setShowJetSettings(false)}
          >
            <JetSettings 
                data={activeJetSettings}
                showSettings={setShowJetSettings}
                groupInfo={jetsList}
                deviceId={activeDevice.id}
                fetch={fetchDeviceData}
                allDevices={actualAllDevices}
              /> 
          </Drawer>
          {/* {showJetSettings ?
            <div className="WaterfallScreen__JetSettings">
            <JetSettings 
              data={activeJetSettings}
              showSettings={setShowJetSettings}
              groupInfo={jetsList}
              deviceId={activeDevice.id}
              fetch={fetchDeviceData}
              allDevices={actualAllDevices}
            /> 
            </div>
            : null} */}
           <SwipeableDrawer
                classes={{
                    root: classes.drawerOverlay,
                    paper: classes.drawerPaper,
                }}
            BackdropProps={{
                    classes: {
                        root: "WaterfallScreen__popup-overlay",
                }
            }}
                anchor="bottom"
                open={bottomSheet}
                onClose={() => setBottomSheet(false)}
                onOpen={() => setBottomSheet(true)}
            >
            <List
                disablePadding={true}
            >
            <ListItem
              onClick={() => {
                history.push("/edit-dev", {
                  state: device,
                });
              }}
              className="WaterfallScreen__bottom-item"
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Параметры устройства" />
            </ListItem>
          </List>
        </SwipeableDrawer>

        </div>
        )}
         </div>
    )
}

export default WaterfallScreen
