import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import "../styles/Main.scss";
import "../styles/EditMeteoScreen.scss";
import {Button, Slider, Typography, InputBase, Divider, ListSubheader } from '@material-ui/core';
import {FormControl, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const EditMeteoScreen = () => {

  const useStyles = makeStyles((theme) => ({
    SliderPadding: {
        paddingTop: `38px`,
    }
}));

const classes = useStyles();

  const device = useLocation().state.state;
  const dispatch = useDispatch();
  // console.log(device.settings);

    const [dataSource, setDataSource] = React.useState("sensor");
    const [brightness, setBrightness] = React.useState([20, 21]);
    const [tmpBrightness, setTmpBrightness] = React.useState([20, 21]);
    const [CO2, setCO2] = React.useState([400, 2000]);
    const [tmpCO2, setTmpCO2] = React.useState([400, 2000]);
    const [color, setColor] = React.useState([800,801]);
    const [tmpColor, setTmpColor] = React.useState([800,801]);
    const [displayDisable, changeDisplay] = React.useState(true);

    const [isCalibrateAvailable, setIsCalibrateAvailable] = useState(true);

    const handleRadioChangeDataSource = (event, newValue) => {
      setDataSource(newValue);
    };

  const handleChangeTmpBrightness = (val) => {
    setTmpBrightness(val)
  }
  const handleChangeTmpCO2 = (val) => {
    setTmpCO2(val)
  }
  const handleChangeTmpColor = (val) => {
    setTmpColor(val)
  }

    const handleChangeDisplay = () => {
      changeDisplay(!displayDisable);
      if (!displayDisable) {
        handleSendData({
          settings: [
          {
            instance: 'disable_display',
            value: "on",
          }
        ],})
      }
      else{
        handleSendData({settings: [
          {
            instance: 'disable_display',
            value: "off",
          }
        ],})
      }
    };
    
  const [openDataSource, setOpenDataSource] = useState(false);
  const [openBrightnessSource, setOpenBrightnessSource] = useState(false);
  const [openBrightnessCO2, setOpenBrightnessCO2] = useState(false);
  const [openColorLmits, setOpenColorLmits] = useState(false);
  const [openCalibrate, setOpenCalibrate] = useState(false);

        
  useEffect(() => {
    dispatch({ type: "HEADING", value: "Параметры устройства" });
    dispatch({ type: "SHOW_BAR" });
    dispatch({ type: "EDIT_SLAVE_PARAMS_CLEAR" });
    // handleSetCalibrateAvailableText();
    if (!Array.isArray(device.settings)) {
      device.settings.source ? setDataSource(device.settings.source) : null;
      device.settings.min_brightness ? setBrightness([device.settings.min_brightness, device.settings.max_brightness]) : null;
      device.settings.min_brightness ? setTmpBrightness([device.settings.min_brightness, device.settings.max_brightness]) : null;
      // device.settings.max_brightness ? setMaxBrightness(device.settings.max_brightness) : null;
      device.settings.co2_max ? setCO2([device.settings.co2_min, device.settings.co2_max]) : null;
      device.settings.co2_max ? setTmpCO2([device.settings.co2_min, device.settings.co2_max]) : null;
      // device.settings.co2_min ? setMinCO2(device.settings.co2_min) : null;
      device.settings.yellow ? setColor([device.settings.yellow,device.settings.red]) : null;
      device.settings.yellow ? setTmpColor([device.settings.yellow,device.settings.red]) : null;
      // device.settings.red ? setColorRed(device.settings.red) : null;

      if(device.settings.disable_display){
        if (device.settings.disable_display == "on") {
          changeDisplay(true)
        } else {
          changeDisplay(false)
        }
      }
      if (!device.settings.calibrate || device.settings.calibrate  == '') {
        setIsCalibrateAvailable(true);
      } else {
        setIsCalibrateAvailable(false);
      }
    }
  }
  ,[]);
    
  const handleSendData = (jsonBody) => {
    console.log("jsonBody, ", jsonBody);

    fetch(SiteUrl + "/devices/" + device.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify(jsonBody),
        method: "PUT",
      })
        .then((response) => response.json())
        .then((json) => console.log(json));
};

  const MenuListBrightness = (props) => {
    return (
      <React.Fragment>
        <List>
          <ListItem 
          className="EditMeteoScreen__list-item"
          onClick={()=>{
            props.onClickDataSource();
          }}
          >
            <ListItemText
              primary="Источник данных"
              secondary={<span>От датчика освещенности или уровня CO<sub>2</sub></span>}
            />
          </ListItem>
          <Divider />
          <ListItem 
          className="EditMeteoScreen__list-item"
          onClick={()=>{
            props.onClickBrightnessSource();
          }}
          >
            <ListItemText
              primary="Яркость при работе от датчика освещенности"
              secondary="Параметры макс. и мин. яркости"
            />
          </ListItem>
          <Divider />
          <ListItem 
          className="EditMeteoScreen__list-item"
          onClick={()=>{
            props.onClickBrightnessCO2();
          }}
          >
            <ListItemText
              primary={<span>Яркость при работе от уровня CO<sub>2</sub></span>}
              secondary={<span>Параметры макс. и мин. яркости от уровня CO<sub>2</sub></span>}
            />
          </ListItem>
          <Divider />
        </List>
      </React.Fragment>
    );
};

  const MenuListColors = (props) => { 
    return (
      <React.Fragment>
        <List>
          <ListItem 
          className="EditMeteoScreen__list-item"
          onClick={()=>{
            props.onClickColorLmits();
          }}
          >
            <ListItemText
              primary={<span>Пределы изменения цвета</span>}
              secondary={<span>При работе от уровня CO<sub>2</sub></span>}
            />
          </ListItem>
          <Divider />
        </List>
      </React.Fragment>
    );
};

  const ElementOther = () => {
    return (
      <React.Fragment>
        <div className="MuiTypography-body1 EditMeteoScreen__text-left">Отключать дисплей</div>
        <div className="MuiTypography-body2 MuiTypography-colorTextSecondary EditMeteoScreen__text-left EditMeteoScreen__mb">Отключать во время простоя</div>
      </React.Fragment>
    );
};

  return (
    <div className="EditMeteoScreen container">
      <Grid>
        <ListSubheader 
        className="EditMeteoScreen__header"
        component="div"
        color="primary"
        >
          Яркость подсветки
        </ListSubheader>
        <div>
          <MenuListBrightness
            onClickDataSource={() => setOpenDataSource(true)}
            onClickBrightnessSource={() => setOpenBrightnessSource(true)}
            onClickBrightnessCO2={() => setOpenBrightnessCO2(true)}
            />
        <ListSubheader 
        className="EditMeteoScreen__header"
        component="div"
        color="primary"
        >
          Цвет подсветки
        </ListSubheader>
        <MenuListColors
          onClickColorLmits={() => setOpenColorLmits(true)}
        />
        <ListSubheader 
        className="EditMeteoScreen__header"
        component="div"
        color="primary"
        >
          Другие настройки
        </ListSubheader>
        <List>
          <FormGroup>
            <FormControlLabel
              className="EditMeteoScreen__switch-width"
              label={<ElementOther/>}
              labelPlacement="start"
              control={
                <Switch
                  color="primary"
                  checked={displayDisable} 
                  onChange={handleChangeDisplay} 
                />
              }
            /> 
            </FormGroup>
          <Divider />
          <ListItem 
          className="EditMeteoScreen__list-item"
          onClick={()=>{
            setOpenCalibrate(true)
          }}
          >
            <ListItemText
              primary={<span>Откалибровать сенсор</span>}
              secondary={<span>Калибровка сенсора CO<sub>2</sub> {isCalibrateAvailable ? "доступно" : "недоступно" }</span>}
            />
          </ListItem>
          <Divider />
        </List>

          {/* Модальное окно "Источник данных" */}
          <Dialog 
            open={openDataSource} 
            onClose={() => {setOpenDataSource(false)}} 
            aria-labelledby="form-dialog-title"
            classes={{
              paper: "EditMeteoScreen__MeteoScreenModal"
            }}
          >
          <DialogTitle id="form-dialog-title">Источник данных</DialogTitle>
          <DialogContent>
            <FormControl>
                <RadioGroup aria-label="gender" name="gender1" value={dataSource} onChange={handleRadioChangeDataSource} color="primary">
                  <FormControlLabel value="sensor" control={<Radio color="primary" />} label="Датчик освещенности" />
                  <FormControlLabel value="co2" control={<Radio color="primary" />} label={<span>Уровень CO<sub>2</sub></span>} />
                </RadioGroup>
            </FormControl>
        
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={()=>{
                setOpenDataSource(false)
                }
              } 
              color="primary"
            >
              Отмена
            </Button>
            <Button 
              onClick={() => {
                handleSendData({
                  settings: [
                    {
                      instance: 'source',
                      value: dataSource,
                    },
                  ],
                }),
                setOpenDataSource(false)
              }
              }
                color="primary">
                Сохранить
              </Button> 
            </DialogActions>
          </Dialog>

          {/* Модальное окно "Яркость при работе от освещения" */}
            <Dialog 
              open={openBrightnessSource} 
              onClose={() => {
                setOpenBrightnessSource(false)
                setTmpBrightness(brightness)
              }} 
              aria-labelledby="form-dialog-title"
              classes={{
                paper: "EditMeteoScreen__MeteoScreenModal",
              }}
            >
              <DialogTitle id="form-dialog-title">Яркость от освещения</DialogTitle>
              <DialogContent
                classes={{
                  root: classes.SliderPadding
                }}
              >
              <Slider
                  max={100}
                  min={0}
                  value={tmpBrightness}
                  valueLabelDisplay="on"
                  onChange={(e, value) => {
                    handleChangeTmpBrightness(value)
                  }}
                  aria-labelledby="MeteoScreen-ActionWork"
                />
                <Grid
                  className="EditMeteoScreen__MeteoScreenModal-grid"
                >
                  <Typography id="MeteoScreen-ActionWork">
                    Мин. и Макс. значения
                  </Typography>

                </Grid>
                  
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={()=> {
                    setOpenBrightnessSource(false)
                    setTmpBrightness(brightness)
                      }
                    }
                  color="primary">
                    Отмена
                </Button>
                <Button onClick={
                  () => {
                    setBrightness(tmpBrightness)
                    handleSendData({
                      settings: [
                        {
                          instance: 'min_brightness',
                          value: tmpBrightness[0],
                        },
                        {
                          instance: 'max_brightness',
                          value: tmpBrightness[1],
                        }
                      ],
                    }),
                    setOpenBrightnessSource(false)
                    }
                  } 
                  color="primary"
                >
                  Сохранить
                </Button> 
              </DialogActions>
            </Dialog>
          
          {/* Модальное окно "Яркость при работе от CO2" */}
            <Dialog 
              open={openBrightnessCO2} 
              onClose={() => {
                setOpenBrightnessCO2(false)
                setTmpBrightness(brightness)
                setTmpCO2(CO2)
              }} 
              aria-labelledby="form-dialog-title"
              classes={{
                paper: "EditMeteoScreen__MeteoScreenModal"
              }}
            >
              <DialogTitle id="form-dialog-title">Яркость от показателя CO<sub>2</sub></DialogTitle>
              <DialogContent
              >
                <Grid
                classes={{
                  root: classes.SliderPadding
                }}>
              <Slider
                  max={100}
                  min={0}
                  value={tmpBrightness}
                  valueLabelDisplay="on"
                  onChange={(e, value) => {
                    handleChangeTmpBrightness(value)
                  }}
                  aria-labelledby="MeteoScreen-ActionWork"
                />
                  <Typography id="MeteoScreen-ActionWork">
                    Мин. и Макс. значения
                  </Typography>
                </Grid>
                <Grid
                classes={{
                  root: classes.SliderPadding
                }}>
                <Slider
                  max={5000}
                  min={400}
                  valueLabelDisplay="on"
                  value={tmpCO2}
                  onChange={(e, value) => {
                    handleChangeTmpCO2(value)
                  }}
                  aria-labelledby="MeteoScreen-ActionWork"
                />
                  <Typography id="MeteoScreen-ActionWork">
                    Минимальное значение при ppm
                  </Typography>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={()=> {
                    setOpenBrightnessCO2(false)
                    setTmpBrightness(brightness)
                    setTmpCO2(CO2)
                      }
                    }
                  color="primary">
                    Отмена
                </Button>
                <Button onClick={
                  () => {
                    setBrightness(tmpBrightness)
                    setCO2(tmpCO2)
                    handleSendData({
                      settings: [
                        {
                          instance: 'min_brightness',
                          value: tmpBrightness[0],
                        },
                        {
                          instance: 'max_brightness',
                          value: tmpBrightness[1],
                        },
                        {
                          instance: 'co2_min',
                          value: tmpCO2[0],
                        },
                        {
                          instance: 'co2_max',
                          value: tmpCO2[1],
                        }
                      ],
                    }),
                    setOpenBrightnessCO2(false)
                    }
                  } 
                  color="primary"
                >
                  Сохранить
                </Button> 
              </DialogActions>
            </Dialog>
                  
          {/* Модальное окно "Пределы изменения цвета" */}
            <Dialog 
              open={openColorLmits} 
              onClose={() => {
                setOpenColorLmits(false)
                setTmpColor(color)
              }} 
              aria-labelledby="form-dialog-title"
              classes={{
                paper: "EditMeteoScreen__MeteoScreenModal"
              }}
            >
              <DialogTitle id="form-dialog-title">Пределы изменения цвета</DialogTitle>
              <DialogContent
                classes={{
                  root: classes.SliderPadding
                }}
              >
                <Slider
                  max={5000}
                  min={400}
                  value={tmpColor}
                  valueLabelDisplay="on"
                  onChange={(e, value) => {
                    handleChangeTmpColor(value)
                  }}
                  aria-labelledby="MeteoScreen-ActionWork"
                />
                <Grid
                  className="EditMeteoScreen__MeteoScreenModal-grid"
                >
                  <Typography id="MeteoScreen-ActionWork">
                    Желтый цвет
                  </Typography>
                  <Typography>
                    Красный цвет
                  </Typography>  
                </Grid>
                
                  
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={()=> {
                    setTmpColor(color)
                    setOpenColorLmits(false)
                      }
                    }
                  color="primary">
                    Отмена
                </Button>
                <Button onClick={
                  () => {
                    setColor(tmpColor)
                    handleSendData({
                      settings: [
                        {
                          instance: 'yellow',
                          value: tmpColor[0],
                        },
                        {
                          instance: 'red',
                          value: tmpColor[1],
                        }
                      ],
                    }),
                    setOpenColorLmits(false)
                    }
                  } 
                  color="primary"
                >
                  Сохранить
                </Button> 
              </DialogActions>
            </Dialog>

          {/* Модальное окно калибровки */}
            <Dialog 
              open={openCalibrate} 
              onClose={() => {setOpenCalibrate(false)}} 
              aria-labelledby="form-dialog-title"
              classes={{
                paper: "EditMeteoScreen__MeteoScreenModal"
              }}
            >
              <DialogTitle id="form-dialog-title"></DialogTitle>
              <DialogContent>
                <Typography id="MeteoScreen-ActionWork">
                  Вы уверены, что хотите начать калибровку устройства? Расположите устройство на проветриваемом месте. Метеостанция отключится на 25 минут.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={()=> {
                    setOpenCalibrate(false)
                      }
                    }
                  color="primary">
                    Отмена
                </Button>
                <Button onClick={
                  () => {
                    fetch(SiteUrl + "/command/" + device.id, {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem("userToken"),
                      },
                      body: JSON.stringify({
                        command: 'calibrate',
                      }),
                      method: 'POST',
                    })
                      .then((response) => response.json())
                      .then((json) => console.log(json));
                    setIsCalibrateAvailable(false),
                    setOpenCalibrate(false)
                    // handleSetCalibrateAvailableText()
                    }
                  } 
                  color="primary"
                >
                  Сохранить
                </Button> 
              </DialogActions>
            </Dialog>
          
        
       </div>
    </Grid>
  </div>
  );
}


export default EditMeteoScreen;