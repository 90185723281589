import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {setHeading, showNavBar, slaveParamClear} from '../actions'

import "../styles/Main.scss";
import "../styles/EditValve.scss";
import {Button, Slider, Typography, InputBase, Divider, ListSubheader } from '@material-ui/core';


function EditValve() {
  const device = useLocation().state.state;
  const dispatch = useDispatch();


    const [activeWork, setActiveWork] = React.useState(5);
    const [passiveWork, setPassiveWork] = React.useState(5);
    const [selectSpeed, setSelectSpeed] = React.useState(3);

    const handleSliderChangeActiveWork = (event, newValue) => {
      setActiveWork(newValue);
    };
    const handleSliderChangePassiveWork = (event, newValue) => {
      setPassiveWork(newValue);
    };
    const handleSelectSpeed = (event, newValue) => {
      setSelectSpeed(newValue);
    };
    

  const [openWorkTime, setOpenWorkTime] = useState(false);
  const [openVelocity, setOpenVelocity] = useState(false);

        
  useEffect(() => {
    dispatch(setHeading('Параметры устройства'));
    dispatch(showNavBar());
    dispatch(slaveParamClear());
    if (!Array.isArray(device.settings)) {
      device.settings.uptime ? setActiveWork(device.settings.uptime) : null;
      device.settings.downtime ? setPassiveWork(device.settings.downtime) : null;
      device.settings.speed ? setSelectSpeed(device.settings.speed) : null;
      }
    },[]);
    

  const handleSendData = (jsonBody) => {

    fetch(SiteUrl + "/devices/" + device.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify(jsonBody),
        method: "PUT",
      })
        .then((response) => response.json())
        .then((json) => console.log(json));
};

  const MenuList = (props) => {
    return (
      <React.Fragment>
        <List>
          <ListItem 
          className="EditValve__list-item"
          onClick={()=>{
            props.onClickWorkTime();
          }}
          >
            <ListItemText
              primary="Время работы и простоя"
              secondary="Временной период, когда клапан открыт/закрыт"
            />
          </ListItem>
          <Divider />
          <ListItem 
          className="EditValve__list-item"
          onClick={()=>{
            props.onClickVelocity();
          }}
          >
            <ListItemText
              primary="Скорость работы"
              secondary="Скорость вращения вентилятора"
            />
          </ListItem>
          <Divider />
        </List>
      </React.Fragment>
    );
};

  return (
    <div className="EditValve container">
      <Grid>
        <ListSubheader 
        className="EditValve__header"
        component="div"
        color="primary"
        >
          Параметры авторежима
        </ListSubheader>
        <div>
          <MenuList
            onClickWorkTime={() => setOpenWorkTime(true)}
            onClickVelocity={() => setOpenVelocity(true)}
          />
          {/* Модальное окно "Время работы" */}
          <div>
          <Dialog 
            open={openWorkTime} 
            onClose={() => {setOpenWorkTime(false)}} 
            aria-labelledby="form-dialog-title"
            classes={{
              paper: "EditValve__WorkTimeModal"
            }}
          >
          <DialogTitle id="form-dialog-title">Время работы</DialogTitle>
          <DialogContent>
            <Grid
              className="EditValve__WorkTimeModal-grid"
            >
            <Typography id="WorkTime-ActionWork">
              Время работы
            </Typography>
            <Grid>
            <InputBase
              className="EditValve__WorkTimeModal-numb"
              value={activeWork}
              margin="none"
            />
            <Typography>
              мин
            </Typography> 
            </Grid> 
          </Grid>
            <Slider
              max={10}
              min={1}
              step={1}
              value={typeof activeWork === 'number' ? activeWork : 1}
              onChange={ handleSliderChangeActiveWork }
              aria-labelledby="WorkTime-ActionWork"
            />
            
            <Grid
            className="EditValve__WorkTimeModal-grid"
            >
              <Typography id="WorkTime-ActionWork">
                Время простоя
              </Typography>
              <Grid>
                <InputBase
                  className="EditValve__WorkTimeModal-numb"
                  value={passiveWork}
                  margin="none"
                />
                <Typography>
                  мин
                </Typography> 
              </Grid> 
            </Grid>
            <Slider
              max={10}
              min={1}
              step={1}
              value={typeof passiveWork === 'number' ? passiveWork : 1}
              onChange={handleSliderChangePassiveWork}
              aria-labelledby="WorkTime-ActionWork"
            />
            
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={()=>{
                setOpenWorkTime(false)
                }
              } 
              color="primary"
            >
              Отмена
            </Button>
            <Button 
              onClick={() => {
                handleSendData({
                  settings: [
                    {
                      instance: 'uptime',
                      value: activeWork,
                    },
                    {
                      instance: 'downtime',
                      value: passiveWork,
                    },
                  ],
                }),
                setOpenWorkTime(false)
              }
              }
                color="primary">
                Сохранить
              </Button> 
            </DialogActions>
          </Dialog>
      </div>
          {/* Модальное окно "Скорость вращения" */}
          <div>
            <Dialog 
              open={openVelocity} 
              onClose={() => {setOpenVelocity(false)}} 
              aria-labelledby="form-dialog-title"
              classes={{
                paper: "EditValve__WorkTimeModal"
              }}
            >
              <DialogTitle id="form-dialog-title">Скорость работы</DialogTitle>
              <DialogContent>
                <Grid
                  className="EditValve__WorkTimeModal-grid"
                >
                  <Typography id="WorkTime-ActionWork">
                    Режим скорости
                  </Typography>
                  <Grid>
                    <InputBase
                      className="EditValve__WorkTimeModal-numb"
                      value={selectSpeed}
                      margin="none"
                    />
                    <Typography>
                      мин
                    </Typography> 
                  </Grid> 
                </Grid>
                <Slider
                  max={5}
                  min={1}
                  value={typeof selectSpeed === 'number' ? selectSpeed : 1}
                  onChange={handleSelectSpeed}
                  aria-labelledby="WorkTime-ActionWork"
                />
                  
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={()=> {
                    setOpenVelocity(false)
                      }
                    }
                  color="primary">
                    Отмена
                </Button>
                <Button onClick={
                  () => {
                    handleSendData({
                      settings: [
                        {
                          instance: 'speed',
                          value: selectSpeed,
                        }
                      ],
                    }),
                    setOpenVelocity(false)
                    }
                  } 
                  color="primary"
                >
                  Сохранить
                </Button> 
              </DialogActions>
            </Dialog>
          </div>
       </div>
    </Grid>
  </div>
  );
}

export default EditValve;