import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function SnackbarComponent() {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Я снекбар");

  const snackbarData = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(snackbarData.visibility);
    setTitle(snackbarData.title);
  }, [snackbarData]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar
      style={{ zIndex: 9999999, marginBottom: 60 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={title}
      action={
        <React.Fragment>
          <Button color="primary" size="small" onClick={handleClose}>
            Скрыть
          </Button>
          {/* <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </React.Fragment>
      }
    />
  );
}

export default SnackbarComponent;
