function snackbarReducer(
  state = { visibility: false, title: "title" },
  action
) {
  switch (action.type) {
    case "SHOW_SNACKBAR":
      return { visibility: true, title: action.value };
    case "HIDE_SNACKBAR":
      return { visibility: false, title: "" };
    default:
      return state;
  }
}

export default snackbarReducer;
