import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";

import smart from "../img/smart.png";
import atmosphere from "../img/atmosphere.png";
import windor from "../img/windor.png";

function Dialogs() {
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.dialogsReducer);
  const [groupName, setGroupName] = useState("");

  const hideGroup = () => {
    dispatch({
      type: "HIDE_GROUP",
    });
  };
  const confirmGroup = () => {
    dispatch({
      type: "ADD_GROUP",
      value: groupName,
    });
    dispatch({
      type: "HIDE_GROUP",
    });
  };

  const hideType = () => {
    dispatch({
      type: "HIDE_TYPE",
    });
  };

  return (
    <>
      <Dialog
        open={redux.groupVisibility}
        onClose={hideGroup}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Добавить расположение</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Комната или группа"
            type="text"
            fullWidth
            onChange={(gr) => {
              setGroupName(gr.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hideGroup} color="primary">
            Отменить
          </Button>
          <Button onClick={confirmGroup} color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={redux.typeVisibility}
        onClose={hideType}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title">
          Выберите тип устройства
        </DialogTitle>
        <List>
          <ListItem
            button
            onClick={() => {
              dispatch({
                type: "CHANGE_TYPE",
                value: 1,
              });
              dispatch({
                type: "HIDE_TYPE",
              });
            }}
          >
            <ListItemAvatar>
              {/* <Avatar src={smart} style={{ backgroundColor: "tomato" }}>
                <img src={smart} alt="s" style={{ width: 10, height: 40 }} />
              </Avatar> */}
              <div
                style={{
                  backgroundColor: "#2B9DA53E",
                  width: 45,
                  height: 45,
                  borderRadius: 100,
                }}
              >
                <img
                  src={smart}
                  alt="smart"
                  style={{ width: 35, height: 35, margin: 5 }}
                />
              </div>
            </ListItemAvatar>
            <ListItemText primary="Vakio Base Smart" secondary="Рекуператор" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              dispatch({
                type: "CHANGE_TYPE",
                value: 2,
              });
              dispatch({
                type: "HIDE_TYPE",
              });
            }}
          >
            <ListItemAvatar>
              {/* <Avatar src={atmosphere} style={{ backgroundColor: "tomato" }} /> */}

              <div
                style={{
                  backgroundColor: "#2B9DA53E",
                  width: 45,
                  height: 45,
                  borderRadius: 100,
                }}
              >
                <img
                  src={atmosphere}
                  alt="atmosphere"
                  style={{ width: 35, height: 35, margin: 5 }}
                />
              </div>
            </ListItemAvatar>
            <ListItemText primary="Vakio Atmosphere" secondary="Метеостанция" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              dispatch({
                type: "CHANGE_TYPE",
                value: 3,
              });
              dispatch({
                type: "HIDE_TYPE",
              });
            }}
          >
            <ListItemAvatar>
              {/* <Avatar src={windor} style={{ backgroundColor: "tomato" }} /> */}
              <div
                style={{
                  backgroundColor: "#2B9DA53E",
                  width: 45,
                  height: 45,
                  borderRadius: 100,
                }}
              >
                <img
                  src={windor}
                  alt="windor"
                  style={{ width: 35, height: 35, margin: 5 }}
                />
              </div>
            </ListItemAvatar>
            <ListItemText primary="Vakio Windor" secondary="Автоклапан" />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}

export default Dialogs;
