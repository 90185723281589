// import React from "react";
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";

import { useDispatch } from "react-redux";
import { hide } from "../actions";

// судя по всему, все модули, которые будут подключаться к
// другим компонетам, нужно подключать и сюда

import { Link } from "react-router-dom";

function Wellcome() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
  }, []);
  return (
    <div style={localStyles.center} className="wellcome">
      <h1>Добро пожаловать</h1>
      <Link to="/signin" className="link" style={localStyles.link}>
        <Button variant="contained" color="primary">
          Продолжить
        </Button>
      </Link>
    </div>
  );
}

export default Wellcome;

// rfce

const localStyles = {
  center: {
    textAlign: "center",
    marginTop: "40vh",
  },
  link: {
    textTransform: "uppercase",
  },
};
