import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import { hide } from "../actions";

import "../styles/Form.scss";
// import "../styles/Signin.scss";

import logo from "../img/logo.png";

function ConfirmPass() {
  const elementRef = useRef(null);
  const [height, setHeight] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const resetEmail = location.state.email;
  const dispatch = useDispatch();
  const [codeError, setCodeError] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);

  const [data, setData] = useState({
    email: resetEmail,
    password: "",
    // confirm_password: "",
    code: "",
    isCodeValid: false,
    isPasswordValid: false,
    passConfirm: "",
  });

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, [elementRef]);

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
  }, []);

  const isFilled = (value) => {
    // тут нужно будет сделать проверку regex'ом
    return value.length !== 0 ? true : false;
  };

  const handleCode = (val) => {
    setCodeError(false);
    if (isFilled(val)) {
      setData({
        ...data,
        code: val,
        isCodeValid: true,
      });
    } else {
      setCodeError(true);
    }
  };

  const handlePasswordChange = (val) => {
    setPasswordErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        password: val,
        isPasswordValid: true,
      });
    } else {
      setPasswordErr(true);
    }
  };

  const handleConfirmPasswordChange = (val) => {
    setConfirmPassErr(false);
    setData({
      ...data,
      passConfirm: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.isCodeValid) {
      setCodeError(true);
      return;
    } else if (!(data.isPasswordValid && data.password.length >= 8)) {
      setPasswordErr(true);
      return;
    } else if (!(data.passConfirm == data.password)) {
      setConfirmPassErr(true);
      return;
    } else {
      fetch(SiteUrl + "/confirm", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
          verify_code: data.code,
        }),
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.error == "22P02") {
            setCodeErr(true);
          } else {
            dispatch({
              type: "SHOW_SNACKBAR",
              value: "Пароль изменён",
            });
            history.push("/signin");
          }
        });
    }
  };

  return (
    <form
      className="form confirm align-center"
      onSubmit={handleSubmit}
      ref={elementRef}
      // style={{ marginTop: "calc((90vh - " + height + "px) / 2)" }}
    >
      <div className="form__logo-container">
        <img className="form__logo" src={logo} alt="logo" />
      </div>
      <h2 className="form__heading">Сброс пароля</h2>
      <p className="form__text">
        {/* Введите код подтверждения и придумайте новый пароль */}
        На указанный E-mail отправлен код сброса пароля, введите его
      </p>

      <TextField
        error={codeError}
        label={codeError ? "Неверный код подтверждения" : "Код подтверждения"}
        type="text"
        className="form__input"
        size="small"
        onChange={(code) => handleCode(code.target.value)}
        variant="outlined"
      />
      <TextField
        error={passwordErr}
        label={passwordErr ? "Неверный пароль" : "Новый пароль"}
        type="password"
        className="form__input"
        size="small"
        onChange={(password) => handlePasswordChange(password.target.value)}
        variant="outlined"
        helperText="Пароль должен содержать не менее 8 знаков"
      />
      <TextField
        error={confirmPassErr}
        label={confirmPassErr ? "Пароли не совпадают" : "Подтверждение пароля"}
        type="password"
        className="form__input"
        size="small"
        onChange={(confirm) =>
          handleConfirmPasswordChange(confirm.target.value)
        }
        variant="outlined"
      />
      <div className="form__bottom">
        <Link className="form__link link" to="/signin">
          Вход
        </Link>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="form__button"
          size="large"
        >
          Сохранить
        </Button>
      </div>
    </form>
  );
}

export default ConfirmPass;
