import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoopIcon from "@material-ui/icons/Loop";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import TextRotationNoneIcon from "@material-ui/icons/TextRotationNone";

import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../../actions";

import "../../styles/ModeControl.scss";

function ModeControl({ id }) {
  const redux = useSelector((state) => state.deviceModes);
  const dispatch = useDispatch();
  const [manual, setManual] = useState("default");
  const [auto, setAuto] = useState("default");
  const [superAuto, setSuperAuto] = useState("default");
  const activeDevice = redux.find((item) => item.id == id);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    switch (activeDevice.capabilities.mode) {
      case "manual":
        setManual("primary");
        break;
      case "auto":
        setAuto("primary");
        break;
      case "super_auto":
        setSuperAuto("primary");
        break;
      default:
        return;
    }

    switch (activeDevice.capabilities.on_off) {
      case "on":
        setDisabled(false);
        break;
      case "off":
        setDisabled(true);
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    if (activeDevice.relation.on_off_dependence == "on") {
      switch (activeDevice.relation.on_off_dependence) {
        case "on":
          setDisabled(true);
          break;
        case "off":
          setDisabled(false);
          break;
        default:
          return;
      }
    } else if (activeDevice.relation.on_off_dependence == "off") {
      switch (activeDevice.capabilities.on_off) {
        case "on":
          setDisabled(false);
          break;
        case "off":
          setDisabled(true);
          break;
        default:
          return;
      }
    }
  }, [redux]);

  const disableAll = () => {
    setManual("default");
    setAuto("default");
    setSuperAuto("default");
  };

  const handleModeChange = (mode) => {
    activeDevice.capabilities.mode = mode;
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    // dispatch({ type: "SET_DEVICES", value: editedDevices });
    // localStorage.setItem('devices', JSON.stringify(editedDevices))
    dispatch(setDevices(editedDevices));

    fetch(SiteUrl + "/devices/" + activeDevice.id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "mode",
            value: mode,
          },
        ],
      }),
      method: "PUT",
    });
  };

  return (
    <div className="mode-control">
      <div className="mode-control__container">
        <div className="mode-control__wrapper">
          <IconButton
            color={manual}
            disabled={disabled}
            className="mode-control__item inflow"
            onClick={() => {
              handleModeChange("manual");
              disableAll();
              setManual("primary");
            }}
          >
            <SettingsInputComponentIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Ручное</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={auto}
            disabled={disabled}
            className="mode-control__item recuperator"
            onClick={() => {
              handleModeChange("auto");
              disableAll();
              setAuto("primary");
            }}
          >
            <TextFormatIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Авто</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={superAuto}
            disabled={disabled}
            className="mode-control__item outflow"
            onClick={() => {
              handleModeChange("super_auto");
              disableAll();
              setSuperAuto("primary");
            }}
          >
            <TextRotationNoneIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Авто +</p>
        </div>
      </div>
    </div>
  );
}

export default ModeControl;
