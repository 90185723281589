import navReducer from "./bottomNav";
import appBarHeading from "./appBarHeading";
import appBarVis from "./appBarVis";
import deviceModes from "./deviceModes";
import slaveParams from "./slaveParams";
import snackbar from "./snackbar";
import dialogsReducer from "./dialogsReducer";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  navReducer,
  appBarHeading,
  appBarVis,
  deviceModes: deviceModes,
  slaveParams: slaveParams,
  snackbar,
  dialogsReducer,
});

export default allReducers;
