import React, { useEffect, useState, useMemo } from "react";
import { AuthContext } from "../components/context";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { show } from "../actions";

import HomeListItem from "./HomeListItem";
import "../styles/Home.scss";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

function Home() {
  const history = useHistory();
  const { signOut } = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.deviceModes);

  const [devices, setDevices] = useState([
    {
      id: 77,
      device_group: "qqq",
      device_name: "Vakio Base",
      device_type: "",
    },
  ]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // const location = useLocation();

  function fetchHomescreenData() {
    // у Макса эта функция называется fetchData + она внутри useeffect!!!!!!!!!!!!!!!!!!!!!!
    setIsError(false);
    setIsLoading(true);
    new Promise((resolve) => {
      resolve(localStorage.getItem("userToken"));
    }).then((token) => {
      console.log(token);
      if (token === null) {
        // надо сделать проверку рефреша, а не ориг токена
        signOut();
        history.push("/wellcome");
        return null;
      }
      // ниже получаем инфу о юзерах
      // fetch(SiteUrl + "/wp-json/wp/v2/devices/?status=private", {
      try {
        fetch(SiteUrl + "/devices", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          // credentials: "include",
          method: "GET",
        })
          .then((response) => response.json())
          .then((json) => {
            if (json == undefined) {
              signOut();
              history.push("/signin");
            } else {
              console.log(json.content);
              localStorage.setItem("devices", JSON.stringify(json.content));
              dispatch({
                type: "SET_DEVICES",
                value: json.content,
              });
              setDevices(json.content);
              setIsLoaded(true);
            }
          });
      } catch (err) {
        console.log(err);
        signOut();
        history.push("/signin");
      }
    });
  }

  useEffect(() => {
    fetchHomescreenData();
  }, []);

  useEffect(() => {
    dispatch(show());
    dispatch({ type: "HEADING", value: "Устройства" });
    dispatch({ type: "SHOW_BAR" });
  }, []);

  const groups = devices
    .map((item) => {
      return item.device_group;
    })
    .filter((val, ind, arr) => arr.indexOf(val) === ind)
    .filter((value) => {
      if (value != "") {
        return value;
      }
    })
    .sort();

  // fetchHomescreenData();

  return (
    <div className="wrapper">
      {!isLoaded ? (
        <Backdrop
          open={!isLoaded}
          style={{
            background: "transparent",
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ) : (
        <div className="home home-container">
          <Fab
            color="primary"
            aria-label="add"
            className="fab"
            onClick={() => {
              history.push("/add");
            }}
          >
            <AddIcon />
          </Fab>
          {groups.length != 0 ? (
            groups.map((itm, ind) => {
              return (
                <div key={ind} className="home__group">
                  <List
                    style={{ padding: 0 }}
                    subheader={
                      <ListSubheader className="home__group-heading">
                        {itm}
                      </ListSubheader>
                    }
                  >
                    {devices
                      .filter((itmInner) => itmInner.device_group == itm)
                      .sort((a, b) => {
                        return a.id > b.id
                          ? 1
                          : a.id == b.id
                          ? 0
                          : a.id < b.id
                          ? -1
                          : 0;
                      })
                      .map((itm) => {
                        return <HomeListItem devices={devices} itm={itm} key={itm.id} />;
                      })}
                  </List>
                </div>
              );
            })
          ) : (
            <div
              className="home__placeholder"
              onClick={() => {
                history.push("/add");
              }}
            >
              <AddIcon className="home__placeholder-icon" />
              <div className="home__placeholder-text">Добавить устройство</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
