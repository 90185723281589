import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function Notifications() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "HEADING", value: "Уведомления" });
    dispatch({ type: "SHOW_BAR" });
  }, []);

  return (
    <div style={{ backgroundColor: "#F2F3F4", height: "100vh" }}>
      <div
        style={{
          fontSize: 16,
          color: "#757575",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          textAlign: "center",
        }}
      >
        Уведомления отсутствуют
      </div>
    </div>
  );
}

export default Notifications;
