import React, {useState} from 'react'
import JetItem from "./JetItem";
import {List} from '@material-ui/core';
import JetSettings from './JetSettings';
import "../styles/WaterfallGroup.scss";

import {
    Typography
} from '@material-ui/core';

const WaterfallGroup = (props) => {

    // const [showJetSettings, setShowJetSettings] = useState(false)
    // const [activeJetSettings, setActiveJetSettings] = useState({})

// console.log("group-device", props.device);
    const HandleSwichOnOff = ((changedJet, status) => {
        props.device.map((groupJet)=>{
            groupJet.jet_id == changedJet.jet_id ? ( 
                status ? groupJet.jet_on_off = "on" : groupJet.jet_on_off = "off"  
                ) : (null);
        })
        handleSendData({
            capabilities: [
            {
              instance: 'waterfall_value',
              value: props.device,
            }
        ],})

        })
        const handleSendData = (jsonBody) => {
            // console.log("jsonBody", jsonBody);
            fetch(SiteUrl + "/devices/" + props.pageId, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("userToken"),
                },
                body: JSON.stringify(jsonBody),
                method: "PUT",
              })
                .then((response) => response.json())
                .then((json) => console.log(json));
        };

    // console.log("props -> ",props);
    return (
        <div className="WaterfallGroup">
            <Typography className="WaterfallGroup__GroupTitle">
                {props.groupName}
            </Typography>
            <List disablePadding >
                {props.device.sort((a,b)=>{return a.jet_id > b.jet_id
                            ? 1
                            : a.jet_id == b.jet_id
                            ? 0
                            : a.jet_id < b.jet_id
                            ? -1
                            : 0;}).map(jet=>{
                    // console.log(jet);
                    return(jet.jet_group_name == props.groupName ?
                        <JetItem
                            key={jet.jet_id}
                            JetInfo={jet}
                            parentDevices={props.parentDevices}
                            setActiveJetSettings={props.setActiveJetSettings}
                            HandleSwichOnOff={HandleSwichOnOff}
                            setShowJetSettings={props.closeSettings}
                            enabled={props.enabled}
                        /> 
                        : null)
                })}
            </List>
            {/* {props.showJetSettings ?
            <div className="JetItem__popup">
                <JetSettings 
                data={props.activeJetSettings}
                showSettings={props.closeSettings}
                groupInfo={props.device}
                deviceId={props.pageId}
                /> 
            </div>
            : null} */}
        </div>
    )
}

export default WaterfallGroup
