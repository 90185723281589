import React from "react";

function Settings() {
  return (
    <div style={{ textAlign: "center", marginTop: 0 }}>
      <h2>Настройки</h2>
    </div>
  );
}

export default Settings;
