import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../../actions";

import "../../styles/SpeedControl.scss";

function SpeedControl({ id }) {
  const redux = useSelector((state) => state.deviceModes);
  const dispatch = useDispatch();
  const init = new Array(7).fill(false);
  const [active, setActive] = useState(init);
  const [disabled, setDisabled] = useState(init);
  const activeDevice = redux.find((item) => item.id == id);
  // console.log(redux);

  useEffect(() => {
    const stored = activeDevice.capabilities.speed - 1;
    let arr = new Array(7).fill(false);
    arr.splice(stored, 1, true);
    setActive(arr);
  }, []);

  const handleSpeedChange = (speed) => {
    activeDevice.capabilities.speed = speed;
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    dispatch(setDevices(editedDevices));

    fetch(SiteUrl + "/devices/" + activeDevice.id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "speed",
            value: speed,
          },
        ],
      }),
      method: "PUT",
    })
      .then((res) => res.json())
      .then((json) => console.log(json));
  };

  useEffect(() => {
    if (activeDevice.capabilities.on_off == "on") {
      setDisabled(new Array(7).fill(false));
    }
    switch (activeDevice.capabilities.mode) {
      case "inflow_max":
      case "outflow_max":
      case "night": {
        // handleSpeedChange(7);
        setDisabled(new Array(7).fill(true));
        break;
      }
      // case "night": {
      //   // handleSpeedChange(1);
      //   let arr = init;
      //   arr.splice(4, 3, true, true, true);
      //   setDisabled(arr);
      //   break;
      // }
      case "inflow":
      case "recuperator":
      case "outflow": {
        setDisabled(init);
        break;
      }
    }
    if (activeDevice.capabilities.on_off == "off") {
      setDisabled(new Array(7).fill(true));
    }

    switch (activeDevice.relation.on_off_dependence) {
      case "on":
        setDisabled(new Array(7).fill(true));

        break;
      case "off":
        // setDisabled(false);
        break;
      default:
        return;
    }
  }, [redux]);

  return (
    <div className="speed-control">
      {/* <h4 className="speed-control__heading">Скорость</h4> */}
      <div className="speed-control__container">
        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[0]}
            color={active[0] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(1);
              setActive([true, false, false, false, false, false, false]);
            }}
          >
            1
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[1]}
            color={active[1] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(2);
              setActive([false, true, false, false, false, false, false]);
            }}
          >
            2
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[2]}
            color={active[2] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(3);
              setActive([false, false, true, false, false, false, false]);
            }}
          >
            3
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[3]}
            color={active[3] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(4);
              setActive([false, false, false, true, false, false, false]);
            }}
          >
            4
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[4]}
            color={active[4] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(5);
              setActive([false, false, false, false, true, false, false]);
            }}
          >
            5
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[5]}
            color={active[5] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(6);
              setActive([false, false, false, false, false, true, false]);
            }}
          >
            6
          </Button>
        </div>

        <div className="speed-control__item">
          <Button
            disableElevation
            variant="contained"
            disabled={disabled[6]}
            color={active[6] ? "primary" : "default"}
            className="speed-control__button"
            onClick={() => {
              handleSpeedChange(7);
              setActive([false, false, false, false, false, false, true]);
            }}
          >
            7
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SpeedControl;
