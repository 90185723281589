import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useDispatch } from "react-redux";
import { show } from "../actions";
import { Link, useHistory } from "react-router-dom";

function Support() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    theme: "",
    title: "",
    message: "",
  });
  const [themeErr, setThemeErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);

  useEffect(() => {
    // dispatch(show());
    dispatch({ type: "HEADING", value: "Связаться с нами" });
    dispatch({ type: "SHOW_BAR" });
  }, []);

  const handleTheme = (val) => {
    setThemeErr(false);
    setData({
      ...data,
      theme: val,
    });
  };

  const handleTitle = (val) => {
    setTitleErr(false);
    setData({
      ...data,
      title: val,
    });
  };

  const handleMessage = (val) => {
    setMessageErr(false);
    setData({
      ...data,
      message: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.theme == "") {
      setThemeErr(true);
      return;
    } else if (data.title == "") {
      setTitleErr(true);
      return;
    } else if (data.message == "") {
      setMessageErr(true);
      return;
    } else {
      const send = JSON.stringify({
        theme: data.theme,
        title: data.title,
        message: data.message,
      });
      fetch(SiteUrl + "/contact-us/", {
        // mode: "cors", // no-cors, *cors, same-origin
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: send,
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
        });
    }
  };
  return (
    <form
      className="form form--border-none align-center"
      onSubmit={handleSubmit}
    >
      <p className="form__text">Выберите тему и опишите суть обращения</p>

      <FormControl variant="outlined" className="form__select" error={themeErr}>
        <InputLabel id="place">
          {!themeErr ? "Тема обращения" : "Поле не должно быть пустым"}
        </InputLabel>
        <Select
          defaultValue={""}
          onChange={(theme) => {
            handleTheme(theme.target.value);
          }}
          label={!themeErr ? "Тема обращения" : "Поле не должно быть пустым"}
        >
          <MenuItem value="support">Техподдержка</MenuItem>
          <MenuItem value="sales">Отдел продаж</MenuItem>
          <MenuItem value="parthnership">Стать партнёром</MenuItem>
        </Select>
      </FormControl>

      <TextField
        error={titleErr}
        label={!titleErr ? "Заголовок обращения" : "Поле не должно быть пустым"}
        // label="Заголовок обращения"
        variant="outlined"
        className="form__input"
        type="text"
        //size="small"
        onChange={(title) => {
          handleTitle(title.target.value);
        }}
      />

      <TextField
        error={messageErr}
        label={!messageErr ? "Текст обращения" : "Поле не должно быть пустым"}
        // label="Текст обращения"
        className="form__input"
        multiline
        rows={5}
        variant="outlined"
        onChange={(message) => {
          handleMessage(message.target.value);
        }}
      />

      <div className="form__bottom">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="form__button"
          size="large"
          style={{ width: "100%" }}
        >
          Подтвердить
        </Button>
      </div>
    </form>
  );
}

export default Support;
