import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import SlaveControl from "./controls/SlaveControl";

import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../actions";
import { useHistory, useLocation } from "react-router-dom";

import "../styles/SlaveSettings.scss";

function SlaveSettings(props) {
  const redux = useSelector((state) => state.deviceModes);
  // const slaveProp = props.location.state;
  const slaveProp = useLocation().state.state;
  const slaveRedux = redux.find((itm) => itm.id == slaveProp.id);
  const reduxControlData = useSelector((state) => state.slaveParams); // данные с дочернего компонента тут
  const dispatch = useDispatch();
  const [controlled, setControlled] = useState(true);
  const [masterId, setMasterId] = useState("");
  const history = useHistory();
  const possibleMasters =
    slaveProp.device_type_id == 1
      ? redux.filter(
          (itm) =>
            itm.verified == 1 &&
            itm.id != slaveProp.id &&
            itm.device_type_id != 3 &&
            itm.relation.on_off_dependence != "on"
        )
      : slaveProp.device_type_id == 3
      ? redux.filter(
          (itm) =>
            itm.verified == 1 &&
            itm.id != slaveProp.id &&
            itm.device_type_id == 2
          // itm.relation.on_off_dependence != "on"
        )
      : [];
  //   console.log(slaveProp);
  //  console.log(possibleMasters);

  useEffect(() => {
    // dispatch(show());
    dispatch({ type: "HEADING", value: "Режим управления" });
    dispatch({ type: "SHOW_BAR" });
    dispatch({ type: "EDIT_SLAVE_PARAMS_CLEAR" });
    if (
      // slaveProp.relation.length != 0 &&
      !Array.isArray(slaveProp.relation) &&
      slaveRedux.relation.dependence.device_id_master
    ) {
      setControlled(
        slaveRedux.relation.on_off_dependence == "on" ? true : false
      );
      const masterNameLoc = redux.find(
        (itm) => itm.id == slaveRedux.relation.dependence.device_id_master
      );
      setMasterId(masterNameLoc ? masterNameLoc.id : possibleMasters[0].id);
    } else {
      setControlled(false);
    }
  }, []);

  const handleOnOff = () => {
    setControlled(!controlled);

    if (masterId != "") {
      fetch(SiteUrl + "/devices/" + slaveProp.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify({
          relation: [
            {
              instance: "on_off_dependence",
              value: controlled ? "off" : "on",
            },
          ],
        }),
        method: "PUT",
      });
      let activeDevice = slaveProp;
      activeDevice.relation.on_off_dependence = controlled ? "off" : "on";
      const editedDevices = redux.filter((item) => item.id != activeDevice.id);
      editedDevices.push(activeDevice);
      dispatch(setDevices(editedDevices));
      // console.log("saved");
    } else {
      // console.log("not saved");
    }
  };

  const handleMasterChange = (val) => {
    const cock = redux.find((itm) => itm.id == val);
    dispatch({ type: "SET_MASTER_ID", value: cock.id });
    setMasterId(val);

    let activeDevice = slaveProp;
    // const masterId = redux.find((itm) => itm.id == val).id;
    const masterIdInner = val;
    activeDevice.relation = Array.isArray(activeDevice.relation)
      ? { dependence: {} }
      : activeDevice.relation;
    activeDevice.relation.dependence.device_id_master = masterIdInner;
    const editedDevices = redux.filter((item) => item.id != activeDevice.id);
    editedDevices.push(activeDevice);
    dispatch(setDevices(editedDevices));
    // console.log(editedDevices);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(reduxControlData);
    let sas = reduxControlData;
    sas.device_id_master = masterId;
    const send = JSON.stringify({
      relation: [
        {
          instance: "on_off_dependence",
          value: "on",
        },
        {
          instance: "dependence",
          value: sas,
        },
      ],
    });
    // console.log(send);
    fetch(SiteUrl + "/devices/" + slaveProp.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: send,
      method: "PUT",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code == 200) {
          dispatch({
            type: "SHOW_SNACKBAR",
            value: "Данные изменены",
          });
          history.push("/");
        } else {
          dispatch({
            type: "SHOW_SNACKBAR",
            value: "Что-то пошло не так",
          });
          console.log(res);
        }
      });
    // console.log(reduxControlData);
    let activeDevice = slaveProp;
    activeDevice.relation.dependence = reduxControlData;
    const editedDevices = redux.filter((item) => item.id != activeDevice.id);
    editedDevices.push(activeDevice);
    dispatch(setDevices(editedDevices));
  };

  return (
    // <div className="slave container align-center">
    <form
      className="slave form form--border-none align-center"
      onSubmit={handleSubmit}
    >
      <div className="switch">
        <div className="switch__label">
          {controlled ? "Управляемое" : "Самоуправляемое"}
        </div>

        <Switch
          color="primary"
          checked={controlled}
          className="switch__component"
          onChange={() => {
            handleOnOff();
          }}
          name="true"
        />
      </div>

      <div>
        <FormControl
          variant="outlined"
          className="form__input slave__form-master"
          disabled={!controlled}
        >
          <InputLabel id="master">Управляющее устройство</InputLabel>
          <Select
            labelId="master"
            // value={masterName}
            // value={redux.find((itm) => itm.id == masterId).device_name}
            value={masterId}
            label="Управляющее устройство"
            onChange={(item) => {
              handleMasterChange(item.target.value);
            }}
          >
            {possibleMasters.map((itm) => (
              <MenuItem value={itm.id} key={itm.id}>
                {itm.device_name} ({itm.device_group})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {masterId != "" ? (
          // <div className="slave__form-dynamic">
          <>
            <h4
              className="slave__form-heading"
              style={!controlled ? { opacity: "0.5" } : {}}
            >
              Параметры режима
            </h4>
            <SlaveControl
              master={masterId}
              status={controlled}
              id={slaveProp.id}
            />
            <div className="form__bottom slave__form-bottom">
              <div style={{ visibility: "hidden" }}></div>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className="form__button"
                size="large"
                disabled={!controlled}
              >
                Сохранить
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </form>
  );
}

export default SlaveSettings;
