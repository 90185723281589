import React, {useState, useEffect} from 'react'
import {ListItem, ListItemText, ListItemAvatar, Avatar, Switch} from '@material-ui/core';
import JetSettings from './JetSettings';
import JetsImgSrc from "../img/jets.png";
import "../styles/JetItem.scss";

const JetItem = (props) => {
    // console.log("jetInfo", props);
    const [switchStatus, setSwitchStatus] = useState(
        props.JetInfo ? (props.JetInfo.jet_on_off == 'on' ? true : false) : true,
      );

    const [allParentDevice, setAllParentDevice] = useState(props.parentDevices)
    // console.log("switchStatus", switchStatus);
    useEffect(() => {

    }, [])  

    const handleOnOff = () => {
        setSwitchStatus(!switchStatus),
        props.HandleSwichOnOff(props.JetInfo, !switchStatus)
    }

    // console.log("AllParentDevice", allParentDevice);

    return (
        <ListItem 
        className="JetItem__Item"
        >
            <ListItemAvatar className="JetItem__ImgBack">
                <Avatar 
                alt="Jet" 
                src={JetsImgSrc} 
                onClick={()=>{
                    props.setActiveJetSettings(props.JetInfo)
                    props.setShowJetSettings(true)
                }}
                />
            </ListItemAvatar>
            <ListItemText
             onClick={()=>{
                props.setActiveJetSettings(props.JetInfo)
                props.setShowJetSettings(true)
            }}
            className="JetItem__cursorPointer"
             primary={<span>Группа форсунок: {props.JetInfo.jet_capabilities.jet_numbers}</span>}
             secondary={props.JetInfo.jet_relation.jet_on_off_dependence == "off" ? (<span>Интервал {props.JetInfo.jet_capabilities.jet_manual} мин</span>) : (
             <span>Управляется: {allParentDevice.filter((val)=>{ return val.id == props.JetInfo.jet_relation.jet_dependence.device_id_master}).map((item)=>{return item.device_name})} </span>
             )}
             />
            <Switch
                color="primary"
                checked={switchStatus}
                disabled={!props.enabled}
                onChange={()=>{handleOnOff()}}
            />
        </ListItem>
    )
}

export default JetItem
