const appBarHeading = (state = "Устройства", action) => {
  switch (action.type) {
    case "HEADING":
      return action.value;

    default:
      return state;
  }
};

export default appBarHeading;
