// import "./App.css";
import React, { useEffect, useState, useMemo } from "react";
import "normalize.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Wellcome from "./components/Wellcome";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Home from "./components/Home";
import ConfirmRegistration from "./components/ConfirmRegistration";
import ResetPass from "./components/ResetPass";
import ConfirmPass from "./components/ConfirmPass";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import Settings from "./components/Settings";
import Support from "./components/Support";
import Device from "./components/Device";
import AddDevice from "./components/AddDevice";
import ConfirmDevice from "./components/ConfirmDevice";
import EditDevice from "./components/EditDevice";
import SlaveSettings from "./components/SlaveSettings";
import NightSettings from "./components/NightSettings";
import Notifications from "./components/Notifications";
import EditValve from "./components/EditValve";
import EditMeteoScreen from "./components/EditMeteoScreen";

import { AuthContext } from "./components/context";

import "./styles/Main.scss";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import Tabs from "./components/Tabs";
import Bar from "./components/Bar";
import SnackbarComponent from "./components/SnackbarComponent";

import { useSelector, useDispatch } from "react-redux";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#009BA4",
      light: "#eeeeee",
      action: "#eeeeee",
    },
    secondary: {
      // main: "#AFCA0B",
      main: "#A83B67",
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h2",
        h2: "h2",
        h3: "h2",
        h4: "h2",
        h5: "h2",
        h6: "h2",
        subtitle1: "h2",
        subtitle2: "h2",
        body1: "span",
        body2: "span",
      },
    },
  },
});

global.SiteUrl = "https://api.vakio.ru";
global.mqtt = "130.193.42.93";

function App() {
  const redux = useSelector((state) => state.deviceModes);
  const dispatchRedux = useDispatch();

  const visibility = useSelector((state) => state.appBarVis);
  const bottomVisibility = useSelector((state) => state.navReducer);
  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
  };

  // console.log(redux)
  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case "RETRIEVE_TOKEN":
        return {
          ...prevState,
          userToken: action.token,
          userRefreshToken: action.refreshToken,
          isLoading: false,
        };
      case "LOGIN":
        return {
          ...prevState,
          userToken: action.token,
          userRefreshToken: action.refreshToken,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...prevState,
          userToken: null,
          userRefreshToken: null,
          isLoading: false,
        };
      case "REGISTER":
        return {
          ...prevState,
          userToken: action.token,
          userRefreshToken: action.refreshToken,
          isLoading: false,
        };
    }
  };

  const [loginState, dispatch] = React.useReducer(
    loginReducer, // функция изменения редьюсера (диспатч)
    initialLoginState // начальное состояние
  );

  const authContext = React.useMemo(
    () => ({
      signIn: (userAccess) => {
        const userToken = userAccess.userToken;
        // const userName = userAccess.username;
        const userRefreshToken = userAccess.userRefreshToken;

        localStorage.setItem("userToken", userToken);
        localStorage.setItem("userRefreshToken", userRefreshToken);
        dispatch({
          type: "LOGIN",
          token: userToken,
          refreshToken: userRefreshToken,
        });
      },
      signOut: () => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userRefreshToken");

        dispatch({ type: "LOGOUT" });
      },
      signUp: () => {},
    }),
    []
  );

  const memoizedValue = useMemo(() => {
    if (localStorage.getItem("devices") != "undefined") {
      dispatchRedux({
        type: "SET_DEVICES",
        value: JSON.parse(localStorage.getItem("devices")),
      });
    } else {
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  // console.log(redux);

  useEffect(() => {
    // dispatchRedux({ type: "SET_DEVICES", value: JSON.parse(localStorage.getItem('devices')) })

    setTimeout(() => {
      let userToken;
      let userRefreshToken;
      userToken = localStorage.getItem("userToken");
      userRefreshToken = localStorage.getItem("userRefreshToken");
      dispatch({
        type: "RETRIEVE_TOKEN",
        token: userToken,
        refreshToken: userRefreshToken,
      });
    }, 1000);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={authContext}>
          <Router>
            {visibility ? <Bar /> : ""}
            <SnackbarComponent />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/signin" component={Signin} />
              <Route path="/signup" component={Signup} />
              <Route path="/code" component={ConfirmRegistration} />
              <Route path="/reset" component={ResetPass} />
              <Route path="/confirm" component={ConfirmPass} />
              <Route path="/wellcome" component={Wellcome} />
              <Route path="/profile" component={Profile} />
              <Route path="/editprofile" component={EditProfile} />
              <Route path="/settings" component={Settings} />
              <Route path="/contact" component={Support} />
              <Route path="/device-:id" component={Device} />
              <Route path="/add" component={AddDevice} />
              <Route path="/confirm-dev" component={ConfirmDevice} />
              <Route path="/edit-dev" component={EditDevice} />
              <Route path="/slave" component={SlaveSettings} />
              <Route path="/night" component={NightSettings} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/valve-settings" component={EditValve} />
              <Route path="/MeteoScreen-settings" component={EditMeteoScreen} />
            </Switch>
            {bottomVisibility ? <Tabs /> : ""}
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;

global.styles = {
  center: {
    textAlign: "center",
    marginTop: 100,
  },

  centerNoMargin: {
    textAlign: "center",
  },

  input: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    width: 250,
    height: 30,
  },

  button: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    width: 250,
    height: 30,
  },

  link: {
    display: "block",
    marginTop: 5,
  },
};
