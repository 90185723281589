import React, { useState, useEffect } from "react";
import { ListItemText, Grid, Typography, Button, Tabs, Tab, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { VictoryChart, VictoryArea, VictoryAxis } from "victory";
import Icon from "@mdi/react";
import { mdiMoleculeCo2, mdiWater, mdiThermometer } from "@mdi/js";
import "../../styles/meteo.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ResponsiveLine } from "@nivo/line";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function meteo({ params, device }) {
  // console.log(device);
  const classes = useStyles();
  // const today = `${new Date().getFullYear()}-\
  // ${String(new Date().getMonth() + 1).padStart(2, "0")}-\
  // ${String(new Date().getDate()).padStart(2, "0")}, \
  // ${String(new Date().getHours()).padStart(2, "0")}:\
  // ${String(new Date().getMinutes()).padStart(2, "0")}:\
  // ${String(new Date().getSeconds()).padStart(2, "0")}`;
  const d = new Date();
  const today =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    ", " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);
  console.log("today", today);
  const [chartState, setChartState] = useState({
    property: "co2",
    date: today, // текущие время и дата
    period: "day",
  });
  const [timeRange, setTimeRange] = useState("day");
  const [dataType, setDataType] = useState("co2");
  const [showpreloader, setShowpreloader] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const [data, setData] = useState([
    {
      id: "japan",
      data: [
        {
          x: "plane",
          y: 3,
        },
        {
          x: "helicopter",
          y: 102,
        },
        {
          x: "boat",
          y: 242,
        },
        {
          x: "train",
          y: 288,
        },
      ],
    },
  ]);
  const [labels, setLabels] = useState([]);
  const [activeParam, setActiveParam] = useState("");

  const handleChangeTimeRange = (event, newValue) => {
    setShowpreloader(true);
    setTimeRange(newValue);
    setChartState({ ...chartState, period: newValue });
    fetchDeviceData({ ...chartState, period: newValue });
  };

  const handleChangeDataType = (event, newValue) => {
    setShowpreloader(true);
    setDataType(newValue);
    setChartState({ ...chartState, property: newValue });
    fetchDeviceData({ ...chartState, property: newValue });
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChartTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    flexContainer: {
      justifyContent: `flex-end`,
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  })(Tabs);

  const ChartTab = withStyles(() => ({
    root: {
      textTransform: "none",
      minWidth: 20,
      minHeight: 20,
      borderRadius: 50,
      padding: `6px 12px`,
      // fontWeight: theme.typography.fontWeightRegular,
      // marginRight: theme.spacing(4),
      "&:hover": {
        color: "#009ba4",
        opacity: 1,
      },
      "&$selected": {
        color: "#009ba4",
        // fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: `#b5ecf2`,
      },
      // '&:focus': {
      //   color: '#40a9ff',
      // },
    },
    "@media (max-width: 400px)": {
      root: {
        padding: `5px 10px`,
      },
    },
    "@media (max-width: 350px)": {
      root: {
        padding: `4px 9px`,
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  const [APIData, setAPIData] = useState({
    labels: [],
    values: [],
  });

  const chartLeftOffset = dataType == "co2" ? 80 : 45;

  useEffect(() => {
    fetchDeviceData(chartState);
  }, []);

  const fetchDeviceData = (jsonBody) => {
    console.log("jsonBody", jsonBody);
    setActiveParam(jsonBody.property);

    // setShowpreloader(true)
    fetch(SiteUrl + "/stats/" + device.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify(jsonBody),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log("rez", json);
        const raw = json.content.new;
        const converted = [
          {
            id: jsonBody.property == "co2" ? "CO2" : jsonBody.property == "hud" ? "Влажность" : jsonBody.property == "temp" ? "Температура" : "",
            data: raw.map((i) => ({
              y: i.y,
              x: i.label,
            })),
            // .filter((f, i) => i % 2 !== 0),
          },
        ];
        setData(converted);
        setLabels(raw.map((i) => i.label));
        setAPIData(json.content);
      })
      .then(() => {
        setShowpreloader(false);
      });
  };

  // console.log(labels);

  const handleClickOpen = () => {
    if (window.screen.width > 450) {
      setShowDialog(true);
    }
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div className="meteo">
      <Grid
        className="meteo__wraper"
        container
        justify="space-evenly"
        // alignItems="center"
      >
        <Grid
          item
          xs={6}
          // alignItems="center"
          className={"meteo__item"}
          // className="meteo__item-padding"
        >
          <Grid container justify="space-between">
            <Typography>
              <span>
                Уровень CO<sub>2</sub>
              </span>
            </Typography>

            <Icon path={mdiMoleculeCo2} alt="co2" size={1} color="white" className="meteo__icon-wraper" />
          </Grid>
          <Grid className="meteo__padding-top" item>
            <ListItemText
              primary={
                params.co2_level ? (
                  <span>
                    <span className="meteo__item-numb">{params.co2_level}</span> ppm
                  </span>
                ) : (
                  <Typography variant="h5">Нет данных</Typography>
                )
              }
              item
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container alignItems="center" className="meteo__small-item-top">
            <Grid xs={8} className="meteo__item-padding" item>
              <Typography variant="body2">
                <span className="meteo__small-title">Влажность</span>
              </Typography>
              <ListItemText
                primary={
                  params.humidity ? (
                    <span>
                      <span className="meteo__small-item-numb">{params.humidity}</span> %
                    </span>
                  ) : (
                    <Typography variant="subtitle2">Нет данных</Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={4} className="meteo__text-center">
              <Icon path={mdiWater} size={1} color="white" className="meteo__icon-wraper" />
            </Grid>
          </Grid>
          <Grid container alignItems="center" className="meteo__small-item-bottom">
            <Grid xs={8} className="meteo__item-padding" item>
              <Typography variant="body2">
                <span className="meteo__small-title">Температура</span>
              </Typography>
              <ListItemText
                primary={
                  params.temperature ? (
                    <span>
                      <span className="meteo__small-item-numb">{params.temperature}</span> °C
                    </span>
                  ) : (
                    <Typography className="meteo__small-title" variant="body1">
                      Нет данных
                    </Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={4} className="meteo__text-center">
              <Icon alt="humidity" path={mdiThermometer} size={1} color="white" className="meteo__icon-wraper" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={("meteo__wraper", "meteo__wraper-chart")} justify="space-evenly">
        <Grid item xs={12} className={"meteo__item"}>
          <Grid container justify="space-between">
            <Grid item xs={5}>
              <ChartTabs variant="scrollable" scrollButtons="off" value={timeRange} onChange={handleChangeTimeRange}>
                <ChartTab value="day" label={<span className="meteo__chart-tabLable">Д</span>}></ChartTab>
                <ChartTab value="week" label={<span className="meteo__chart-tabLable">Н</span>}></ChartTab>
                <ChartTab value="twoweeks" label={<span className="meteo__chart-tabLable">2Н</span>}></ChartTab>
                <ChartTab value="month" label={<span className="meteo__chart-tabLable">М</span>}></ChartTab>
              </ChartTabs>
            </Grid>
            <Grid item xs={6}>
              <ChartTabs variant="scrollable" scrollButtons="off" value={dataType} onChange={handleChangeDataType}>
                <ChartTab value="co2" label={<Icon path={mdiMoleculeCo2} alt="co2" size={"1.5em"} />} />
                <ChartTab value="hud" label={<Icon path={mdiWater} size={"1.5em"} />} />
                <ChartTab value="temp" label={<Icon alt="temperature" path={mdiThermometer} size={"1.5em"} />} />
              </ChartTabs>
            </Grid>
          </Grid>
          <div>
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient id="myGradient" x1="0%" x2="0%" y1="0%" y2="100%">
                  <stop offset="0%" stopColor="#31b7bc" />
                  <stop offset="100%" stopColor="rgba(49, 183, 188, 0.5)" />
                </linearGradient>
              </defs>
            </svg>
            {showpreloader ? (
              <div className="meteo__preloader">
                <CircularProgress />
              </div>
            ) : APIData.values.length != 0 ? (
              <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
                {/* <Chart /> */}
                <VictoryChart
                  minDomain={{ x: 0, y: 0 }}
                  maxDomain={{ x: APIData.values.length - 1, y: Math.max(...APIData.values) + 5 }}
                  width={400}
                  height={300}
                  padding={{ bottom: 0, top: 10, left: chartLeftOffset, right: 10 }}
                >
                  <VictoryArea
                    style={{
                      data: {
                        fill: "url(#myGradient)",
                        strokeWidth: 3,
                        stroke: "#2da9ad",
                      },
                    }}
                    interpolation="natural"
                    data={APIData.values}
                  />
                  <VictoryAxis
                    tickFormat={(d) => {
                      if (dataType == "co2") {
                        return d + " ppm";
                      } else if (dataType == "temp") {
                        return d + " °C";
                      }
                      return d + " %";
                    }}
                    dependentAxis
                    style={{
                      axis: { stroke: "" },
                    }}
                  />
                </VictoryChart>

                <VictoryChart
                  height={50}
                  width={400}
                  padding={{ bottom: 30, top: 0, left: chartLeftOffset + 5, right: 15 }}
                  style={{
                    parent: {
                      marginTop: `-20px`,
                    },
                  }}
                >
                  <VictoryAxis
                    style={{
                      axis: { stroke: "" },
                    }}
                    tickValues={APIData.labels}
                  />
                </VictoryChart>
              </div>
            ) : (
              <Grid className="meteo__chart-placeholder" container justify="center" alignItems="center">
                <span className="meteo__text-center" style={{ textAlign: "center" }}>
                  Недостаточно данных для построения графика, попробуйте позже.
                </span>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>

      <div>
        <Dialog
          fullScreen
          open={showDialog}
          onClose={handleClose}
          // TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                График
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{ height: "90vh", width: "100vw" }}>
            <ResponsiveLine
              data={data}
              margin={{ top: 27, right: 35, bottom: 80, left: 65 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: activeParam == "co2" ? 200 : activeParam == "hud" ? 10 : 20,
                max: activeParam == "co2" ? 4000 : activeParam == "hud" ? 100 : 40,
                stacked: true,
                reverse: false,
              }}
              colors={["#009BA4"]}
              enableArea={true}
              areaBaselineValue={activeParam == "co2" ? 200 : activeParam == "hud" ? 10 : 20}
              yFormat=" >-.0f"
              axisTop={null}
              axisRight={null}
              axisLeft={{
                format: (v) => `${v} ${activeParam == "co2" ? "ppm" : activeParam == "hud" ? "%" : "°C"}`,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: "Менеджер",
                legendPosition: "middle",
                legendOffset: 55,
                // tickRotation: -33,
                tickRotation: -90,
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              tooltip={(input) => {
                return (
                  <div style={{ background: "white", padding: "5px 10px", border: "1px solid grey", borderRadius: 4 }}>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Значение:</span> {input.point.data.y} {activeParam == "co2" ? "ppm" : activeParam == "hud" ? "%" : "°C"}
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Время:</span> {input.point.data.x}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default meteo;
