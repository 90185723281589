import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Divider,
  Avatar,
  Button,
  Fade,
  Collapse,
  Zoom
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InfoIcon from "@material-ui/icons/Info";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AuthContext } from "./context";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useSelector, useDispatch } from "react-redux";
import logo from "../img/logo.png";
import "../styles/Main.scss";
import { CollectionsSharp } from "@material-ui/icons";

function Bar() {
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const heading = useSelector((state) => state.appBarHeading);
  const visibility = useSelector((state) => state.appBarVis);
  const [state, setState] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const { signOut } = React.useContext(AuthContext);
  const [devs, setDevs] = useState(0);

  const count_desc =
    devs === 1
      ? " устройство"
      : devs > 1 && devs < 5
      ? " устройства"
      : " устройств";

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(Boolean(open));
  };
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      // width: `420px`,
      left: `auto`,
      margin: `0 auto`,
    },
    drawerOverlay: {
      width: `420px`,
      margin: `0 auto`,
      overflow: `hidden`
    }
  }));

  const classes = useStyles();
  // useEffect(() => {
  //   (function () {
  //     fetch(SiteUrl + "/me?access_token=" + localStorage.getItem("userToken"), {
  //       method: "GET",
  //     })
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // console.log(json);
  //         if (json.code == 401) {
  //           signOut();
  //           history.push("/signin");
  //         } else {
  //           localStorage.setItem("userData", JSON.stringify(json));
  //           setUser(json.content);
  //         }
  //       });
  //   })();
  // }, []);

  // const userData = JSON.parse(localStorage.getItem("userData")).content;

  useEffect(() => {
    // (async function () {
    try {
      new Promise((res) => {
        res(localStorage.getItem("userToken"));
      }).then((token) => {
        fetch(SiteUrl + "/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.code === 200) {
              try {
                localStorage.setItem("userData", JSON.stringify(json.content));
                setUser(json.content);
              } catch (e) {
                console.log("Что-то не так");
              }
            } else {
              signOut();
            }
          })
          .catch((error) => console.log(error));
      });
    } catch (error) {
      console.log(error);
    }
    try {
      new Promise((res) => {
        res(localStorage.getItem("userToken"));
      }).then((token) => {
        fetch(SiteUrl + "/devices", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.code === 200) {
              setDevs(json.content.length ? json.content.length : 0);
            }
          })
          .catch((error) => console.log(error));
      });
    } catch (err) {
      console.log(err);
    }
    // })();
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        style={{ maxWidth: 421, left: "auto", right: "auto" }}
      >
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{heading}</Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      
      <SwipeableDrawer
        BackdropProps={{
          classes: {
            root: "device__popup-overlay",
          }
        }}
        // SlideProps={{
        //   direction: "down",
        //   timeout: {
        //     // enter: 1000,
        //     exit: 200,
        //     // appear: 20000,
        //    }
        // }}
        classes={{
          root: classes.drawerOverlay,
          paper: classes.drawerPaper,
        }}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {/* <Zoom
          in={state}
          timeout = {{
            enter: 500, 
            exit: 500,
          }}
        > */}
        <div className="menu-wrapper" style={{ width: 300 }}>
          <List>
            <ListItem>
              <img
                src={logo}
                alt="logo"
                style={{ width: "70%", margin: "0 auto" }}
              />
            </ListItem>
          </List>

          <Divider />

          <List className="user">
            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState(false);
                history.push("/profile");
              }}
            >
              <ListItemAvatar>
                {/* <Avatar
                  alt="User"
                  src="https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png"
                /> */}
                <Avatar style={{ background: "#009BA4" }}>
                  {user.name.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.name}
                // secondary={
                //   // localStorage.getItem("devices") != "undefined" ||
                //   // localStorage.getItem("devices") != null
                //   localStorage.getItem("devices")
                //     ? JSON.parse(localStorage.getItem("devices")).length +
                //       " прибор(-ов)"
                //     : "Нет приборов"
                // }
                secondary={devs + count_desc}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={user.email} secondary={user.phone} />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState(false);
                history.push("/");
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="На главную" />
            </ListItem>

            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState(false);
                history.push("/profile");
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Профиль" />
            </ListItem>

            {/* <ListItem
            style={{cursor: 'pointer'}}
              onClick={() => {
                setState(false);
                history.push("/settings");
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Настройки" />
            </ListItem> */}

            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState(false);
                history.push("/contact");
              }}
            >
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary="Связаться с нами" />
            </ListItem>

            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() => {
                setState(false);
                signOut();
                history.push("/signin");
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Выход" />
            </ListItem>
          </List>
          </div>
        {/* </Zoom> */}
      </SwipeableDrawer>
    </>
  );
}

export default Bar;
