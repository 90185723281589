const slaveParams = (state = {}, action) => {
  switch (action.type) {
    case "SET_MASTER_ID":
      // state.device_id_master = action.value;
      // return state;
      return {
        ...state,
        device_id_master: action.value,
      };
    case "EDIT_SLAVE_PARAMS_BASE":
      // state.mode = action.value;
      // return state;
      return {
        ...state,
        mode: action.value,
      };
    case "EDIT_SLAVE_PARAMS_METEO_MIN":
      // state.min_value = action.value;
      // return state;
      return {
        ...state,
        min_value: action.value,
      };
    case "EDIT_SLAVE_PARAMS_METEO_STEP":
      // state.step = action.value;
      // return state;
      return {
        ...state,
        step: action.value,
      };
    case "EDIT_SLAVE_PARAMS_METEO_PARAM":
      // state.parametr = action.value;
      // return state;
      return {
        ...state,
        parametr: action.value,
      };
    case "EDIT_SLAVE_PARAMS_METEO_MODE":
      // state.mode = action.value;
      // return state;
      return {
        ...state,
        mode: action.value,
      };
    case "EDIT_SLAVE_PARAMS_CLEAR":
      // state = {};
      // return state;
      return {};
    default:
      return state;
  }
};

export default slaveParams;
