import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoopIcon from "@material-ui/icons/Loop";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";

import Switch from "@material-ui/core/Switch";

import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../../actions";

import "../../styles/ModeControl.scss";

//import AppIcons from "../icons/AppIcons";

function ModeControl({ id }) {
  const redux = useSelector((state) => state.deviceModes);
  const activeDevice = redux.find((item) => item.id == id);
  const dispatch = useDispatch();
  const [inflow, setInflow] = useState("default");
  const [recuperator, setRecuperator] = useState("default");
  const [outflow, setOutflow] = useState("default");
  const [night, setNight] = useState("default");
  const [inflowMax, setInflowMax] = useState("default");
  const [outflowMax, setOutflowMax] = useState("default");
  const [disabled, setDisabled] = useState(false);

  const [winter, setWinter] = useState(false);
  //   activeDevice.capabilities.winter === null
  //     ? false
  //     : activeDevice.capabilities.winter === true
  //     ? true
  //     : activeDevice.capabilities.winter === false
  //     ? false
  //     : false
  // );

  useEffect(() => {
    switch (activeDevice.capabilities.mode) {
      case "inflow":
        setInflow("primary");
        break;
      case "recuperator":
        setRecuperator("primary");
        break;
      case "winter":
        setRecuperator("primary");
        break;
      case "outflow":
        setOutflow("primary");
        break;
      case "night":
        setNight("primary");
        break;
      case "inflow_max":
        setInflowMax("primary");
        break;
      case "outflow_max":
        setOutflowMax("primary");
        break;
      default:
        return;
    }
    switch (activeDevice.capabilities.on_off) {
      case "on":
        setDisabled(false);
        break;
      case "off":
        setDisabled(true);
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    if (activeDevice.relation.on_off_dependence == "on") {
      switch (activeDevice.relation.on_off_dependence) {
        case "on":
          setDisabled(true);
          break;
        case "off":
          setDisabled(false);
          break;
        default:
          return;
      }
    } else if (activeDevice.relation.on_off_dependence == "off") {
      switch (activeDevice.capabilities.on_off) {
        case "on":
          setDisabled(false);
          break;
        case "off":
          setDisabled(true);
          break;
        default:
          return;
      }
    }
    switch (activeDevice.capabilities.winter) {
      case "on":
        setWinter(true);
        break;
      case "off":
        setWinter(false);
        break;
      default:
        setWinter(false);
    }
  }, [redux]);

  const disableAll = () => {
    setInflow("default");
    setRecuperator("default");
    setOutflow("default");
    setNight("default");
    setInflowMax("default");
    setOutflowMax("default");
  };

  const handleModeChange = (mode) => {
    //if (mode == "winter") mode = "recuperator";
    activeDevice.capabilities.mode = mode;
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    // dispatch({ type: "SET_DEVICES", value: editedDevices });
    // localStorage.setItem("devices", JSON.stringify(editedDevices));
    dispatch(setDevices(editedDevices));

    fetch(SiteUrl + "/devices/" + activeDevice.id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "mode",
            value: mode,
          },
        ],
      }),
      method: "PUT",
    })
      .then((res) => res.json())
      .then((json) => console.log(json));
  };

  const handleNight = () => {
    // activeDevice.capabilities.mode = "night";

    activeDevice.capabilities.mode = "night";
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    // dispatch({ type: "SET_DEVICES", value: editedDevices });
    // localStorage.setItem("devices", JSON.stringify(editedDevices));
    dispatch(setDevices(editedDevices));

    let send = [
      {
        instance: "mode",
        value: "night",
      },
    ];

    if (activeDevice.capabilities.hasOwnProperty("night")) {
      send.push({
        instance: "speed",
        value: activeDevice.capabilities.night.night_speed,
      });
    } else {
      send.push({
        instance: "night",
        value: {
          night_speed: "1",
          daily: "off",
          time: "21:00",
        },
      });
      send.push({
        instance: "speed",
        value: "1",
      });
    }

    console.log(send);

    new Promise((res) => {
      res(localStorage.getItem("userToken"));
    }).then((token) => {
      fetch(SiteUrl + "/devices/" + activeDevice.id, {
        // mode: "no-cors", // no-cors, *cors, same-origin
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          capabilities: send,
        }),
        method: "PUT",
      })
        .then((res) => res.json())
        .then((json) => {
          // console.log(json);
          // dispatch(updated_devices());
        });
    });
  };

  const handleWinterChange = (value) => {
    if (recuperator == "primary") {
      // disableAll();
      // setRecuperator("primary");
      handleModeChange(winter ? "recuperator" : "winter");
    }

    setWinter(!winter);
    activeDevice.capabilities.winter = winter ? "off" : "on";
    const editedDevices = redux.filter((item) => item.id != id);
    editedDevices.push(activeDevice);
    dispatch(setDevices(editedDevices));
    console.log(winter);
    fetch(SiteUrl + "/devices/" + activeDevice.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "winter",
            value: winter ? "off" : "on",
          },
        ],
      }),
      method: "PUT",
    })
      .then((res) => res.json())
      .then((json) => console.log(json));
  };

  return (
    <div className="mode-control">
      <div className="mode-control__container">
        <div className="mode-control__wrapper">
          <IconButton
            color={inflow}
            disabled={disabled}
            className="mode-control__item inflow"
            onClick={() => {
              handleModeChange("inflow");
              disableAll();
              setInflow("primary");
            }}
          >
            <ArrowForwardIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Приток</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={recuperator}
            disabled={disabled}
            className="mode-control__item recuperator"
            onClick={() => {
              let rec_mode = "recuperator";
              if (winter === true) {
                rec_mode = "winter";
              }
              handleModeChange(rec_mode);
              disableAll();
              setRecuperator("primary");
            }}
          >
            <LoopIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Рекуперация</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={outflow}
            disabled={disabled}
            className="mode-control__item outflow"
            onClick={() => {
              handleModeChange("outflow");
              disableAll();
              setOutflow("primary");
            }}
          >
            <ArrowBackIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Вытяжка</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={inflowMax}
            disabled={disabled}
            className="mode-control__item inflow_max"
            onClick={() => {
              handleModeChange("inflow_max");
              disableAll();
              setInflowMax("primary");
            }}
          >
            {/* <FontAwesomeIcon
              className={"mode-control__icon"}
              icon={faAngleDoubleRight}
            /> */}
            <DoubleArrowIcon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Макс. приток</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={night}
            disabled={disabled}
            className="mode-control__item-night"
            onClick={() => {
              // handleModeChange("night");
              handleNight();
              disableAll();
              setNight("primary");
            }}
          >
            {/* <FontAwesomeIcon className={"mode-control__icon"} icon={faMoon} /> */}
            <Brightness3Icon className={"mode-control__icon"} />
          </IconButton>
          <p className="mode-control__text">Ночной</p>
        </div>

        <div className="mode-control__wrapper">
          <IconButton
            color={outflowMax}
            disabled={disabled}
            className="mode-control__item outflow_max"
            onClick={() => {
              handleModeChange("outflow_max");
              disableAll();
              setOutflowMax("primary");
            }}
          >
            {/* <FontAwesomeIcon
              className={"mode-control__icon"}
              icon={faAngleDoubleLeft}
            /> */}
            <DoubleArrowIcon
              className={"mode-control__icon"}
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
          <p className="mode-control__text">Макс. вытяжка</p>
        </div>
      </div>
      <div className="mode-control__winter__status">
        <div className="mode-control__winter__text">
          зимний режим
          {/* <AppIcons 
            icon="humidity"
            width="30"
            height="30"
            color={"#000"}
          /> */}
        </div>
        <div className="mode-control__winter__icon">
          <FontAwesomeIcon
            className={"mode-control__icon__winter"}
            icon={faSnowflake}
          />
        </div>
        <div className="winter__toggle">
          <Switch
            color="primary"
            checked={winter}
            //disabled={false}
            className="switch__component"
            onChange={handleWinterChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ModeControl;
