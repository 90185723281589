const deviceModes = (state = [], action) => {
  switch (action.type) {
    case "SET_DEVICES":
      return action.value;
    default:
      return state;
  }
};

export default deviceModes;
