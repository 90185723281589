import React, { useState, useEffect } from "react";
import {
  Switch,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { Banner, StaticBanner } from "material-ui-banner";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import HighlightIcon from "@material-ui/icons/Highlight";
import LinkIcon from "@material-ui/icons/Link";
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SpeedControl from "./controls/SpeedControl";
import ValveSpeedControl from "./controls/ValveSpeedControl";
import ModeControl from "./controls/ModeControl";
import ValveModeControl from "./controls/ValveModeControl";
import Meteo from "./controls/Meteo";

import WaterfallScreen from "./WaterfallScreen";

import grey from "@material-ui/core/colors/grey";

import { useDispatch, useSelector } from "react-redux";
import { setDevices } from "../actions";
const accent = grey.A200;

import "../styles/Device.scss";

function Device() {
  const redux = useSelector((state) => state.deviceModes);
  const device = useLocation().state.pickedDevice;
  const allDevices = useLocation().state.allDevices;
  const activeDevice = redux.find((item) => item.id == device.id);
  const deviceName = device.device_name;
  const history = useHistory();

  const [tooltipVis, setTooltipVis] = useState(false);
  const [banner, setBanner] = useState(false);
  const [status, setStatus] = useState(
    activeDevice.capabilities.on_off == "on"
      ? true
      : device.capabilities.on_off == "off"
      ? false
      : false
  );
  const [disabled, setDisabled] = useState(
    activeDevice.relation.on_off_dependence == "on"
      ? true
      : activeDevice.relation.on_off_dependence == "off"
      ? false
      : false
  );
  const [buttonDisabled, setButtonDisabled] = useState(
    activeDevice.capabilities.on_off == "on"
      ? true
      : device.capabilities.on_off == "off"
      ? false
      : false
  );
  const [bottomSheet, setBottomSheet] = useState(false);

  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: `420px`,
      margin: `0 auto`,
    },
    drawerOverlay: {
      width: `420px`,
      margin: `0 auto`,
    }
  }));

  const classes = useStyles();


  useEffect(() => {
    // dispatch({ type: "HEADING", value: deviceName });
    dispatch({ type: "HEADING", value: "Управление устройством" });
    dispatch({ type: "SHOW_BAR" });
    if (activeDevice.relation.on_off_dependence == "on") {
      setBanner(true);
    }

    setTooltipVis(false);
    document.body.addEventListener("click", () => setTooltipVis(false));

    return function cleanup() {
      document.body.removeEventListener("click", () => setTooltipVis(false));
    };
  }, []);

  useEffect(() => {
    setButtonDisabled(
      activeDevice.capabilities.on_off == "on"
        ? true
        : device.capabilities.on_off == "off"
        ? false
        : false
    );
  }, [redux]);

  useEffect(() => {
    if (activeDevice.capabilities.mode == "night") {
      setTooltipVis(true);
    } else {
      setTooltipVis(false);
    }
  }, [activeDevice.capabilities.mode]);

  const handleStatusChange = (value) => {
    setStatus(!status);
    activeDevice.capabilities.on_off = status ? "off" : "on";
    const editedDevices = redux.filter((item) => item.id != device.id);
    editedDevices.push(activeDevice);
    // dispatch({ type: "SET_DEVICES", value: editedDevices });
    console.log(editedDevices);
    dispatch(setDevices(editedDevices));
    // localStorage.setItem("devices", JSON.stringify(editedDevices));

    fetch(SiteUrl + "/devices/" + device.id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify({
        capabilities: [
          {
            instance: "on_off",
            value: status ? "off" : "on",
          },
        ],
      }),
      method: "PUT",
    });
  };

  const handleCommand = (command) => {
    new Promise((resolve) => {
      resolve(localStorage.getItem("userToken"));
    }).then((token) => {
      fetch(SiteUrl + "/command/" + activeDevice.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          command: command,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => console.log(json));
    });
  };

  // console.log("ALL DEVICES", allDevices);

  return (
    <div className="device-provider">
      {/* <StaticBanner /> */}
      <Banner
        icon={<LockIcon />}
        label="Параметры этого устройства заблокированы, потому что оно управляется другим устройством"
        dismissButtonLabel="Скрыть"
        buttonLabel="Изменить"
        buttonProps={{ color: "primary" }}
        dismissButtonProps={{ color: "primary" }}
        buttonOnClick={() => {
          history.push("/slave", {
            state: device,
          });
        }}
        onClose={() => setBanner(false)}
        open={banner}
      />

      <div
        className="device container"
        style={banner ? { paddingTop: 20 } : null}
      >
        <div>
        {device.device_type_id == 4 ? (
           <WaterfallScreen 
            switchChange={handleStatusChange}
            device={device}
            allDevices={allDevices}
           />
          
        ) : device.device_type_id != 2 ? (
          <div className="device__status">
            <div htmlFor="status" className="device__label">
              {status ? "Активно" : "Неактивно"}
            </div>

            <Tooltip
              open={tooltipVis}
              placement="left"
              onClose={() => setTooltipVis(false)}
              onOpen={() => setTooltipVis(true)}
              title="Настроить"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
            >
              <MoreVertIcon
                className="device__menu-dots"
                onClick={() => setBottomSheet(true)}
                style={{ marginRight: 10 }}
              />
            </Tooltip>

            <Switch
              color="primary"
              checked={status}
              disabled={disabled}
              className="switch__component"
              onChange={handleStatusChange}
            />
          </div>
        ) : (
          
          <div className="device__status">
            <div htmlFor="status" className="device__label">
              Метеостанция
            </div>

            <HighlightIcon
              style={{ marginLeft: "auto", marginRight: 20 }}
              onClick={() => {
                handleCommand("wakeup");
              }}
            />

            <Tooltip
              open={tooltipVis}
              placement="left"
              onClose={() => setTooltipVis(false)}
              onOpen={() => setTooltipVis(true)}
              title="Настроить"
              arrow
            >
              <MoreVertIcon onClick={() => setBottomSheet(true)} />
            </Tooltip>
          </div>
        
        )}
        <div className="device__controls">
          {device.device_type_id == 1 ? (
            [
              <ModeControl id={device.id} key={1} />,
              <SpeedControl id={device.id} key={2} />,
            ]
          ) : device.device_type_id == 2 ? (
            <Meteo params={device.properties} device={device} />
            ) : device.device_type_id == 3 ? (
              [
                <ValveModeControl id={device.id} key={1} />,
                <ValveSpeedControl id={device.id} key={2} />,
              ]
            ) : device.device_type_id == 4 ? (
              [
                // <ValveModeControl id={device.id} key={1} />,
                // <ValveSpeedControl id={device.id} key={2} />,
              ]
            ) : (
            [
              <ModeControl id={device.id} key={1} />,
              <SpeedControl id={device.id} key={2} />,
            ]
          )}
        </div>
        </div>
      </div>
      <SwipeableDrawer
          classes={{
            root: classes.drawerOverlay,
            paper: classes.drawerPaper,
          }}
          BackdropProps={{
            classes: {
              root: "device__popup-overlay",
            }
          }}
          anchor="bottom"
          open={bottomSheet}
          onClose={() => setBottomSheet(false)}
          onOpen={() => setBottomSheet(true)}
          disableSwipeToOpen={true}
        >
          <List
            disablePadding={true}
          >
            <ListItem
              onClick={() => {
                history.push("/edit-dev", {
                  state: device,
                });
              }}
              className="device__bottom-item"
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Параметры устройства" />
            </ListItem>

            {device.device_type_id != 2 ? (
              <>
                <ListItem
                  onClick={() => {
                    history.push("/slave", {
                      state: device,
                    });
                  }}
                  className="device__bottom-item"
                >
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Режим управления" />
                </ListItem>

                <ListItem
                  onClick={() => {
                    history.push("/night", {
                      state: device,
                    });
                  }}
                  className="device__bottom-item"
                >
                  <ListItemIcon>
                    <Brightness3Icon />
                  </ListItemIcon>
                  <ListItemText primary="Параметры ночного режима" />
                </ListItem>
              </>
            ) : (
              ""
            )}
            {device.device_type_id == 3 ? (
              <>
                <ListItem
                  onClick={() => {
                    history.push("/valve-settings", {
                      state: device,
                    });
                  }}
                  className="device__bottom-item"
                >
                  <ListItemIcon>
                    <SettingsRemoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Настройки прибора" />
                </ListItem>
              </>
            ) : (
              ""
            )}
            {device.device_type_id == 2 ? (
              <>
                <ListItem
                  onClick={() => {
                    history.push("/MeteoScreen-settings", {
                      state: device,
                    });
                  }}
                  className="device__bottom-item"
                >
                  <ListItemIcon>
                    <SettingsRemoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Настройки прибора" />
                </ListItem>
              </>
            ) : (
              ""
            )}
          </List>
        </SwipeableDrawer>
    </div>
  );
}

export default Device;