import React from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./context";
import { BottomNavigation } from "@material-ui/core";
import { BottomNavigationAction } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { useSelector } from "react-redux";

import "../styles/Main.scss";

function Tabs() {
  const history = useHistory();
  const { signOut } = React.useContext(AuthContext);

  const visibility = useSelector((state) => state.navReducer);

  const [value, setValue] = React.useState("devices");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabs">
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationAction
          label="Профиль"
          value="add"
          icon={
            <AccountCircleIcon
            // color="primary"
            />
          }
          onClick={() => {
            history.push("/profile");
          }}
        />
        <BottomNavigationAction
          label="Устройства"
          value="devices"
          icon={<HomeIcon />}
          onClick={() => {
            history.push("/");
          }}
        />
        <BottomNavigationAction
          label="Уведомления"
          value="signout"
          icon={<NotificationsIcon />}
          onClick={() => {
            // signOut();
            history.push("/notifications");
          }}
        />
      </BottomNavigation>
    </div>
  );
}

export default Tabs;
