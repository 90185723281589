import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";

import "../styles/NightSettings.scss";

function NightSettings() {
  const history = useHistory();
  const redux = useSelector((state) => state.deviceModes);
  const id = useLocation().state.state.id;
  const activeDevice = redux.find((item) => item.id == id);
  const dispatch = useDispatch();
  const init = new Array(7).fill(false);
  const [active, setActive] = useState(init);
  const [switchStatus, setSwitchStatus] = useState(false);
  const [pickedTime, setPickedTime] = useState(
    activeDevice.capabilities.hasOwnProperty("night")
      ? activeDevice.capabilities.night.time
      : "21:00"
  );
  const [data, setData] = useState({
    night_speed: activeDevice.capabilities.night
      ? activeDevice.capabilities.night.night_speed
      : "1",
    daily: activeDevice.capabilities.night
      ? activeDevice.capabilities.night.daily
      : "off",
    time: activeDevice.capabilities.night
      ? activeDevice.capabilities.night.time
      : "21:00",
  });

  useEffect(() => {
    // dispatch(show());
    dispatch({ type: "HEADING", value: "Ночной режим" });
    dispatch({ type: "SHOW_BAR" });
    dispatch({ type: "EDIT_SLAVE_PARAMS_CLEAR" });

    if (activeDevice.capabilities.hasOwnProperty("night")) {
      const stored = activeDevice.capabilities.night.night_speed - 1;
      let arr = new Array(7).fill(false);
      arr.splice(stored, 1, true);
      setActive(arr);

      if (activeDevice.capabilities.night.daily == "on") {
        setSwitchStatus(true);
      } else if (activeDevice.capabilities.night.daily == "off") {
        setSwitchStatus(false);
      }
    }
  }, []);

  const handleSpeed = (speed) => {
    setData({
      ...data,
      night_speed: speed.toString(),
    });
  };

  const handleDaily = () => {
    setSwitchStatus(!switchStatus);
    setData({
      ...data,
      daily: !switchStatus ? "on" : "off",
    });
  };

  // console.log(data);

  const handleTime = (val) => {
    setData({
      ...data,
      time: val,
    });
  };

  const handleSubmit = () => {
    // console.log(data);
    let send = {
      capabilities: [
        {
          instance: "night",
          value: data,
        },
      ],
    };

    if (activeDevice.capabilities.mode == "night") {
      send.capabilities.push({
        instance: "speed",
        value: data.night_speed,
      });
    }

    new Promise((res) => {
      res(localStorage.getItem("userToken"));
    }).then((token) => {
      fetch(SiteUrl + "/devices/" + id, {
        // mode: "no-cors", // no-cors, *cors, same-origin
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(send),
        method: "PUT",
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.code == 200) {
            // dispatch(updated_devices());
            dispatch({
              type: "SHOW_SNACKBAR",
              value: "Данные сохранены",
            });
            history.push("/");
          }
        });
      // .then(() => dispatch(updated_devices()));
    });
  };

  return (
    <div className="night">
      <div className="night__heading">Скорость по умолчанию</div>

      <div className="night__chip-container">
        <Chip
          variant="outlined"
          icon={active[0] ? <DoneIcon /> : <></>}
          color={active[0] ? "primary" : "default"}
          label="1"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([true, false, false, false, false, false, false]);
            handleSpeed(1);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[1] ? <DoneIcon /> : <></>}
          color={active[1] ? "primary" : "default"}
          label="2"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, true, false, false, false, false, false]);
            handleSpeed(2);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[2] ? <DoneIcon /> : <></>}
          color={active[2] ? "primary" : "default"}
          label="3"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, false, true, false, false, false, false]);
            handleSpeed(3);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[3] ? <DoneIcon /> : <></>}
          color={active[3] ? "primary" : "default"}
          label="4"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, false, false, true, false, false, false]);
            handleSpeed(4);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[4] ? <DoneIcon /> : <></>}
          color={active[4] ? "primary" : "default"}
          label="5"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, false, false, false, true, false, false]);
            handleSpeed(5);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[5] ? <DoneIcon /> : <></>}
          color={active[5] ? "primary" : "default"}
          label="6"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, false, false, false, false, true, false]);
            handleSpeed(6);
          }}
        />

        <Chip
          variant="outlined"
          icon={active[6] ? <DoneIcon /> : <></>}
          color={active[6] ? "primary" : "default"}
          label="7"
          clickable
          className="night__chip"
          onClick={() => {
            setActive([false, false, false, false, false, false, true]);
            handleSpeed(7);
          }}
        />
      </div>

      <div className="night__switch-container">
        <div className="night__heading">Включать ежедневно</div>
        <Switch
          checked={switchStatus}
          onChange={() => handleDaily()}
          name="checkedA"
          color="primary"
        />
      </div>

      <TextField
        variant="outlined"
        id="time"
        className="night__picker"
        label="Время включения"
        type="time"
        defaultValue={pickedTime}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        // inputProps={{
        //   step: 300, // 5 min
        // }}
        onChange={(time) => {
          handleTime(time.target.value);
        }}
      />

      <Button
        variant="contained"
        className="night__button"
        size="large"
        color="primary"
        onClick={() => {
          handleSubmit();
        }}
      >
        Сохранить
      </Button>
    </div>
  );
}

export default NightSettings;
