export const hide = () => {
  return {
    type: "HIDE",
  };
};

export const show = () => {
  return {
    type: "SHOW",
  };
};

export const setDevices = (val) => {
  localStorage.setItem("devices", JSON.stringify(val));
  return {
    type: "SET_DEVICES",
    value: val,
  };
};

export const setHeading = (val) => {
  return {
    type: "HEADING",
    value: val,
  };
};

export const showNavBar = () => {
  return {
    type: "SHOW_BAR",
  };
};

export const slaveParamClear = () => {
  return {
    type: "EDIT_SLAVE_PARAMS_CLEAR",
  };
};