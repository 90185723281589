import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useDispatch, useSelector } from "react-redux";
import { show } from "../actions";

import "../styles/Main.scss";
import "../styles/EditDevice.scss";

const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    marginTop: 30,

    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      marginTop: 30,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: "rgba(0, 0, 0, .03)",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    fontWeight: "500",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
      padding: 0,
      fontWeight: "500",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

function EditDevice() {
  // const device = props.location.state;
  const device = useLocation().state.state;
  console.log(device);
  const history = useHistory();
  const dispatch = useDispatch();
  // const redux = useSelector((state) => state.deviceModes);
  // const activeDevice = redux.find(item => item.id == id);
  const [wifiErr, setWifiErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [expanded, setExpanded] = useState(device.verified == 0 ? true : false);
  const [data, setData] = useState({
    device_name: null,
    device_group: null,
  });
  const [wifiData, setWifiData] = useState({
    login_wifi: null,
    pass_wifi: null,
  });
  const [valid, setValid] = useState({
    wifi: false,
    pass: false,
  });

  useEffect(() => {
    // dispatch(show());
    dispatch({ type: "HEADING", value: "Параметры" });
    dispatch({ type: "SHOW_BAR" });
  }, []);

  const handleName = (val) => {
    setData({
      ...data,
      device_name: val,
    });
  };

  const handleGroup = (val) => {
    setData({
      ...data,
      device_group: val,
    });
  };

  const handleWifi = (val) => {
    setWifiErr(false);
    if (isFilled(val)) {
      setWifiData({ ...wifiData, login_wifi: val });
      setValid({
        ...valid,
        wifi: true,
      });
    } else {
      setWifiErr(true);
    }
  };

  const handleWifiPass = (val) => {
    setPassErr(false);
    if (isFilled(val)) {
      setWifiData({ ...wifiData, pass_wifi: val });
      setValid({
        ...valid,
        pass: true,
      });
    } else {
      setPassErr(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    for (let key in data) {
      if (data[key] == null) {
        delete data[key];
      }
    }
    fetch(SiteUrl + "/devices/" + device.id, {
      // mode: "no-cors", // no-cors, *cors, same-origin
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      body: JSON.stringify(data),
      method: "PUT",
    }).then((r) => {
      dispatch({
        type: "SHOW_SNACKBAR",
        value: "Настройки прибора изменены",
      });
      history.push("/");
    });
  };

  // const handleWifiSubmitqqq = (e) => {
  //   e.preventDefault();
  //   localStorage.setItem("wifi", wifiData.login_wifi);
  //   localStorage.setItem("wifiPass", wifiData.pass_wifi);
  //   const send = JSON.stringify({
  //     device_name: data.title,
  //     device_group: data.group,
  //   });

  //   // console.log(send);
  //   // console.log(data.user_id);
  //   console.log(device.id);
  //   fetch(SiteUrl + "/devices/" + device.id, {
  //     // mode: "cors", // no-cors, *cors, same-origin
  //     // credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("userToken"),
  //     },
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((json) => {
  //       console.log(json);
  //       history.push("/confirm-dev", { toSend: json, local: wifiData });
  //     });
  // };

  const handleWifiSubmit = () => {
    if (!valid.wifi) {
      setWifiErr(true);
      return;
    } else if (!valid.pass) {
      setPassErr(true);
      return;
    } else {
      fetch(SiteUrl + "/devices/" + device.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        method: "GET",
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else {
            console.log(response);
            setWifiErr(true);
            setPassErr(true);
          }
        })
        .then((json) => {
          console.log(json);
          history.push("/confirm-dev", { toSend: json, local: data });
        });
    }
  };

  const handleDelete = () => {
    fetch(SiteUrl + "/devices/" + device.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      },
      method: "DELETE",
    }).then((r) => {
      dispatch({
        type: "SHOW_SNACKBAR",
        value: "Прибор удалён",
      });
      history.push("/");
    });
  };
  return (
    <div
      className="edit-device form form--border-none align-center"
    >
      <form
        className="align-center"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <p className="form__text edit-device__text">
          Введите данные нового прибора
        </p>
        <TextField
          label="Имя прибора"
          variant="outlined"
          className="form__input"
          type="text"
          size="small"
          defaultValue={device.device_name}
          onChange={(user) => handleName(user.target.value)}
        />
        <TextField
          label="Группа прибора"
          variant="outlined"
          className="form__input"
          type="text"
          size="small"
          defaultValue={device.device_group}
          onChange={(group) => handleGroup(group.target.value)}
        />

        <div className="form__bottom">
          <Button
            color="secondary"
            // size="large"
            onClick={() => {
              handleDelete();
            }}
          >
            Удалить
          </Button>

          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="form__button"
            size="large"
          >
            Сохранить
          </Button>
        </div>
      </form>

      <Accordion
        square
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
        className="edit-device__accordion"
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          Изменить Wi-Fi прибора
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <p className="form__text edit-device__text">
              Если вы долгое время не получаете обратной связи от устройства,
              попробуйте сменить Wi-Fi
            </p>

            <TextField
              error={wifiErr}
              label={
                !wifiErr
                  ? "Название вашей Wi-Fi сети"
                  : "Поле не должно быть пустым"
              }
              // label="Название вашей Wi-Fi сети"
              defaultValue={data.login_wifi}
              variant="outlined"
              className="edit-device__input-wifi"
              type="text"
              size="small"
              onChange={(wifi) => {
                handleWifi(wifi.target.value);
              }}
            />

            <TextField
              error={passErr}
              label={
                !passErr
                  ? "Пароль от вашей Wi-Fi сети"
                  : "Поле не должно быть пустым"
              }
              // label="Пароль от вашей Wi-Fi сети"
              defaultValue={data.pass_wifi}
              variant="outlined"
              className="edit-device__input-wifi"
              type="text"
              size="small"
              onChange={(pass) => {
                handleWifiPass(pass.target.value);
              }}
            />
            <div className="edit-device__button-container">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className="form__button edit-device__button"
                size="large"
                onClick={() => {
                  handleWifiSubmit();
                }}
              >
                Далее
              </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default EditDevice;
