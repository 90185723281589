import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { show } from "../actions";

function EditProfile() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    isUsernameValid: false,
    isEmailValid: false,
    isPhoneValid: false,
    isPasswordValid: true,
    passConfirm: "",
  });
  const [usernameErr, setUsernameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.deviceModes);
  const history = useHistory();

  useEffect(() => {
    dispatch(show());
    dispatch({ type: "HEADING", value: "Редактировать профиль" });
    dispatch({ type: "SHOW_BAR" });
    setUsernameErr(false);
    setEmailErr(false);
    setPhoneErr(false);
    setData({
      ...data,
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      isUsernameValid: true,
      isEmailValid: true,
      isPhoneValid: true,
      isPasswordValid: true,
    });
  }, []);

  const isFilled = (value) => {
    return value.length !== 0 ? true : false;
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleUsername = (val) => {
    setUsernameErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        name: val,
        isUsernameValid: true,
      });
    } else {
      setUsernameErr(true);
    }
  };

  const handleEmail = (val) => {
    setEmailErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        email: val,
        isEmailValid: true,
      });
    } else {
      setEmailErr(true);
    }
  };

  const handlePhone = (val) => {
    setPhoneErr(false);
    if (isFilled(val) && val.match(/^\d+$/)) {
      setData({
        ...data,
        phone: val,
        isPhoneValid: true,
      });
    } else {
      setPhoneErr(true);
    }
  };

  const handlePassword = (val) => {
    setPasswordErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        password: val,
        isPasswordValid: true,
      });
    } else {
      setPasswordErr(true);
    }
  };

  const handleConfirmPassword = (val) => {
    setConfirmPassErr(false);
    setData({
      ...data,
      passConfirm: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.isUsernameValid) {
      setUsernameErr(true);
      return;
    } else if (!validateEmail(data.email)) {
      setEmailErr(true);
      return;
    } else if (!data.isPhoneValid) {
      setPhoneErr(true);
      return;
    } else if (!data.isPasswordValid) {
      setPasswordErr(true);
      return;
    } else if (!(data.passConfirm === data.password)) {
      setConfirmPassErr(true);
      return;
    } else {
      let send = {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
      };

      if (send.password === "") {
        delete send.password;
      }
      console.log(send);
      fetch(SiteUrl + "/user/" + userData.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify(send),
        method: "PUT",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.error) {
            switch (json.message) {
              case "SQLSTATE[HY093]: Invalid parameter number: :name":
                setUsernameErr(true);
              case "SQLSTATE[HY093]: Invalid parameter number: :email":
                setEmailErr(true);
              case "SQLSTATE[HY093]: Invalid parameter number: :password":
                setPasswordErr(true);
              // default:
              //   false;
            }
            console.log(json.error);
          } else {
            dispatch({
              type: "SHOW_SNACKBAR",
              value: "Данные сохранены",
            });
          }
        })
        .then(() => {
          history.push("/profile");
          history.go(0);
        });
    }
  };

  return (
    <form
      className="form align-center form--border-none"
      onSubmit={handleSubmit}
    >
      <p className="form__text">Введите новые данные профиля</p>
      <TextField
        error={usernameErr}
        label={!usernameErr ? "Имя" : "Неверное имя"}
        className="form__input"
        variant="outlined"
        type="text"
        size="small"
        defaultValue={userData.name}
        // placeholder="Имя"
        onChange={(name) => handleUsername(name.target.value)}
      />
      <TextField
        error={emailErr}
        label={!emailErr ? "E-mail" : "Неверный E-mail"}
        className="form__input"
        variant="outlined"
        type="text"
        size="small"
        defaultValue={userData.email}
        // placeholder="Email"
        onChange={(user) => handleEmail(user.target.value)}
      />
      <TextField
        error={phoneErr}
        label={!phoneErr ? "Телефон" : "Неверный телефон"}
        className="form__input"
        variant="outlined"
        type="phone"
        size="small"
        defaultValue={userData.phone}
        // placeholder="Email"
        onChange={(user) => handlePhone(user.target.value)}
      />
      <TextField
        error={passwordErr}
        label={!passwordErr ? "Пароль" : "Неверный пароль"}
        label={!passwordErr ? "Пароль" : "Неверный пароль"}
        className="form__input"
        variant="outlined"
        type="password"
        size="small"
        // placeholder="Новый пароль"
        onChange={(user) => handlePassword(user.target.value)}
      />
      <TextField
        error={confirmPassErr}
        label={!confirmPassErr ? "Подтвердите пароль" : "Пароли не совпадают"}
        className="form__input"
        variant="outlined"
        type="password"
        size="small"
        // placeholder="Подтвердите пароль"
        onChange={(passConf) => handleConfirmPassword(passConf.target.value)}
      />

      <div className="form__bottom">
        <Link className="form__link link" to="/profile">
          К профилю
        </Link>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="form__button"
          size="large"
        >
          Подтвердить
        </Button>
      </div>
    </form>
  );
}

export default EditProfile;
