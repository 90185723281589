import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hide } from "../actions";

import logo from "../img/logo.png";

import "../styles/Form.scss";
// import "../styles/Signup.scss";

function Signup() {
  const elementRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [height, setHeight] = useState(null);
  const [data, setData] = useState({
    username: "",
    phone: "",
    email: "",
    password: "",
    isUsernameValid: false,
    isEmailValid: false,
    isPhoneValid: false,
    isPasswordValid: false,
    passConfirm: "",
  });
  const [usernameErr, setUsernameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, [elementRef]);

  useEffect(() => {
    dispatch(hide());
    dispatch({ type: "HIDE_BAR" });
  }, []);

  const isFilled = (value) => {
    // тут нужно будет сделать проверку regex'ом
    return value.length !== 0 ? true : false;
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleUsername = (val) => {
    setUsernameErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        username: val,
        isUsernameValid: true,
      });
    } else {
      setUsernameErr(true);
    }
  };

  const handleEmail = (val) => {
    setEmailErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        email: val,
        isEmailValid: true,
      });
    } else {
      setEmailErr(true);
    }
  };

  const handlePhone = (val) => {
    setPhoneErr(false);
    if (isFilled(val) && val.match(/^\d+$/)) {
      setData({
        ...data,
        phone: val,
        isPhoneValid: true,
      });
    } else {
      setPhoneErr(true);
    }
  };

  const handlePassword = (val) => {
    setPasswordErr(false);
    if (isFilled(val)) {
      setData({
        ...data,
        password: val,
        isPasswordValid: true,
      });
    } else {
      setPasswordErr(true);
    }
  };

  const handleConfirmPassword = (val) => {
    setConfirmPassErr(false);
    setData({
      ...data,
      passConfirm: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.isUsernameValid) {
      setUsernameErr(true);
      return;
    } else if (!validateEmail(data.email)) {
      setEmailErr(true);
      return;
    } else if (!data.isPhoneValid) {
      setPhoneErr(true);
      return;
    } else if (!(data.isPasswordValid && data.password.length >= 8)) {
      setPasswordErr(true);
      return;
    } else if (!(data.passConfirm === data.password)) {
      setConfirmPassErr(true);
      return;
    } else {
      fetch(SiteUrl + "/user", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.username,
          email: data.email,
          password: data.password,
          phone: data.phone,
        }),
        method: "POST",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.error) {
            // switch (json.message) {
            //   case "SQLSTATE[HY093]: Invalid parameter number: :name":
            //     setUsernameErr(true);
            //   case "SQLSTATE[HY093]: Invalid parameter number: :email":
            //     setEmailErr(true);
            //   case "SQLSTATE[HY093]: Invalid parameter number: :password":
            //     setPasswordErr(true);
            // }

            if (json.message == "SQLSTATE[HY093]: Invalid parameter number: :name") setUsernameErr(true);
            if (json.message == "SQLSTATE[HY093]: Invalid parameter number: :email") setEmailErr(true);
            if (json.message == "SQLSTATE[HY093]: Invalid parameter number: :password") setPasswordErr(true);
            if (json.error == "23505")
              dispatch({
                type: "SHOW_SNACKBAR",
                value: "Аккаунт с таким E-mail уже существует, попробуйте использовать другой E-mail",
              });
          } else {
            history.push("/code", { email: data.email });
          }
        });
    }
  };

  return (
    <form
      className="form signup align-center"
      ref={elementRef}
      onSubmit={handleSubmit}
      // style={{ marginTop: "calc((90vh - " + height + "px) / 2)" }}
    >
      <div className="form__logo-container">
        <img className="form__logo" src={logo} alt="logo" />
      </div>
      <h2 className="form__heading">Регистрация</h2>
      <p className="form__text">Введите данные для регистрации</p>

      <TextField
        error={usernameErr}
        label={!usernameErr ? "Имя *" : "Неверное имя"}
        // label="Имя"
        variant="outlined"
        className="form__input"
        type="text"
        size="small"
        onChange={(username) => handleUsername(username.target.value)}
      />
      <TextField
        error={emailErr}
        label={!emailErr ? "E-mail *" : "Неверный E-mail"}
        variant="outlined"
        className="form__input"
        type="text"
        size="small"
        onChange={(email) => handleEmail(email.target.value)}
        helperText="E-mail будет использован для авторизации"
      />
      <TextField
        error={phoneErr}
        label={!phoneErr ? "Телефон *" : "Неверный телефон"}
        variant="outlined"
        className="form__input"
        type="tel"
        size="small"
        onChange={(phone) => handlePhone(phone.target.value)}
      />
      <TextField
        error={passwordErr}
        label={!passwordErr ? "Пароль *" : "Неверный пароль"}
        variant="outlined"
        className="form__input"
        type="password"
        size="small"
        onChange={(password) => handlePassword(password.target.value)}
        helperText="Пароль должен содержать не менее 8 знаков"
      />
      <TextField
        error={confirmPassErr}
        label={!confirmPassErr ? "Подтвердите пароль *" : "Пароли не совпадают"}
        variant="outlined"
        className="form__input"
        type="password"
        size="small"
        onChange={(passConf) => handleConfirmPassword(passConf.target.value)}
      />

      <div className="form__bottom">
        <Link className="form__link link" to="/signin">
          Вход
        </Link>
        <Button variant="contained" type="submit" color="primary" className="form__button" size="large">
          Далее
        </Button>
      </div>
    </form>
  );
}

export default Signup;
