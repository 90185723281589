import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import InfoIcon from "@material-ui/icons/Info";

import { useDispatch, useSelector } from "react-redux";
import { show } from "../actions";

import "../styles/Profile.scss";

function Profile() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const [userDataqq, setUserData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  // });
  // console.log(userData);
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.deviceModes);

  useEffect(() => {
    dispatch(show());
    dispatch({ type: "HEADING", value: "Профиль" });
    dispatch({ type: "SHOW_BAR" });

    // (async function () {
    // try {
    //   new Promise((res) => {
    //     res(localStorage.getItem("userToken"));
    //   }).then((token) => {
    //     fetch(SiteUrl + "/me", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + token,
    //       },
    //       method: "GET",
    //     })
    //       .then((response) => response.json())
    //       .then((json) => {
    //         if (json.code === 200) {
    //           try {
    //             localStorage.setItem("userData", JSON.stringify(json.content));
    //             setUserData(json.content);
    //             console.log(json.content);
    //           } catch (e) {
    //             console.log("Что-то не так");
    //           }
    //         } else {
    //           dispatch({
    //             type: "SHOW_SNACKBAR",
    //             value: "Что-то пошло не так",
    //           });
    //         }
    //       })
    //       .catch((error) => console.log(error));
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    // })();
  }, []);
  return (
    <div className="profile">
      <div className="profile__background">
        {/* <img
          src="https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png"
          alt="avatar"
          width="30"
        /> */}
        <Avatar style={{ background: "#009BA4" }} className="profile__avatar">
          {userData.name.charAt(0).toUpperCase()}
        </Avatar>
        <h2 className="profile__heading">{userData.name}</h2>
        <Link to="/editprofile" className="profile__link">
          Редактировать
        </Link>
      </div>

      <List className="profile__list">
        <Divider />
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={userData.email} secondary="E-mail" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={userData.phone} secondary="Телефон" />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              JSON.parse(localStorage.getItem("devices"))
                ? JSON.parse(localStorage.getItem("devices")).length +
                  " приборов"
                : "Нет приборов"
            }
            secondary="Доступно"
          />
        </ListItem>
        <Divider />
      </List>
    </div>
  );
}

export default Profile;
