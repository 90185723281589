import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { 
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Chip,
  Select,
  Switch,
  MenuItem,
  Button,
  Input,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  InputBase,
  Slider,
  Typography,
  CircularProgress,
  Snackbar
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import "../styles/JetSettings.scss"
import { Fragment } from 'react';

const GROUP_PLACEHOLDER = "Выберите расположение форсунок"


const JetSettings = (props) => {

  const useStyles = makeStyles((theme) => ({
    tabWrapper: {
        flexDirection: `row`,
    },
    TabIcon: {
        margin: `0`
    },
    TabContainer: {
      flex: `1 1 auto`,
      alignItems: `center`
    },
    TabFlexContainer: {
      justifyContent: `space-between`
    }
}));

const classes = useStyles();

    const [places, setPlaces] = useState([GROUP_PLACEHOLDER]);
    const [activeJet, setActiveJet] = useState(props.data)
    const [allJets, setAllJets] = useState([]);
    const [allParentDevice, setAllParentDevice] = useState([])
    const [disableJet, setDisableJet] = useState([])
    const [snackBar, setSnackBar] = useState({show:false, message:"Ошибка"})
    const [checkedJet, setCheckedJet] = useState(activeJet.jet_capabilities.jet_numbers.split(','))
    const [groupName, setGroupName] = React.useState(activeJet.jet_group_name);
    const [selectParent, setSelectParent] = React.useState(0);
    const [dependence, setDependence] = React.useState(activeJet.jet_relation.jet_on_off_dependence);

    const [openConfirmedDelete, setOpenConfirmedDelete] = React.useState(false);
    const [savingData, setSavingData] = React.useState(false);

    const [openAddPlace, setOpenAddPlace] = React.useState(false);
    const [addPlace, setAddPlace] = React.useState("");

    const [openGroupName, setOpenGroupName] = React.useState(false);
    const [openSelectParent, setOpenSelectParent] = React.useState(false);
    const defaultPlaces = ["Спальня", "Детская"];

    const [pauseAfterWork, setPauseAfterWork] = React.useState(activeJet.jet_capabilities.jet_manual);
    const [humidity, setHumidity] = React.useState(50);
    
    const handleSetPauseAfterWork = (event, newValue) => {
      setPauseAfterWork(newValue);
    };
    const handleSliderChangeHumidity = (event, newValue) => {
      setHumidity(newValue);
    };
    const handleChangeGroupName = (event) => {
      setGroupName(event.target.value);
    };
    const handleChangeSelectParent = (event) => {
      setSelectParent(event.target.value);
    };
    const handleCloseGroupName = () => {
      setOpenGroupName(false);
    };
    const handleOpenGroupName = () => {
      setOpenGroupName(true);
    };
    const handleCloseSelectParent = () => {
      setOpenSelectParent(false);
    };
    const handleOpenSelectParent = () => {
      setOpenSelectParent(true);
    };
    const handleSetDependence = (val) => {
      // console.log("handleSetDependence", val);
      dependence == "off" ? setDependence("on") : setDependence("off")
    };
  
    const handleSetAddPlace = (newPlace) => {
      let flag = true;
      if (newPlace == '') {
        flag=false
      }else{
        places.forEach((item)=>{
          if (newPlace == item) {
            flag=false;
          }
        })
      }
      if (flag) {
        places.push(newPlace)
      }
    };

    const handleUpdateJetData = (val) => {
      if (val.every((i)=>!i.checked)) {
        setSnackBar({show:true, message: "Выберите хотя бы одну форсунку"})
        setSavingData(false)
        return;
      }
      if (dependence == "on" && selectParent == 0) {
        setSnackBar({show:true, message: "Выберите Управляющее устройство"})
        setSavingData(false)
        return;
      }
      if (activeJet.jet_id == undefined) {
        activeJet.jet_id = props.groupInfo.length ? Math.max(...props.groupInfo.map((jet)=>{
          return jet.jet_id
        }))+1 : 1
      }
      if (groupName == GROUP_PLACEHOLDER) {
        activeJet.jet_group_name = "Нет группы"
      } else {
        activeJet.jet_group_name = groupName
      }
      // groupName == GROUP_PLACEHOLDER ? activeJet.jet_group_name="Нет группы" : null
      activeJet.jet_on_off = "on"
      activeJet.jet_capabilities = {
        jet_numbers: val.reduce((arr, jet, index)=>{
          if (jet.checked) {
            arr.push(index+1)
          } 
          return arr
        },[]).join(","),
        jet_manual: pauseAfterWork
      }
      activeJet.jet_relation={
        jet_on_off_dependence: dependence,
        jet_dependence: {
          device_id_master: selectParent, 
          mode: "humidity", 
          value: humidity
        }
      }
      // console.log("val",val);
      // console.log("groupName",groupName);
      // console.log("activeJet",activeJet);
      // console.log("new ID", activeJet.jet_id);
      uploadData(activeJet)
      // props.showSettings(false)
    };
    
    // console.log("handleUpdateJetData__data", activeJet.jet_id);
    const handleDeleteJet = () => {
      const data = props.groupInfo.filter((item)=>{
        return item.jet_id != activeJet.jet_id})
      handleSendData(data)
    }
    
    
    useEffect(() => {

    
      // console.log("allJets##", allJets);
      const newAllDisableJet = props.groupInfo
      .map((jet) => {
        // console.log("jet", jet.jet_capabilities.jet_numbers);
          return jet.jet_capabilities.jet_numbers;
      })
      .map((item)=>{
        // console.log(item.split(','));
        return item.split(',').map((item)=>{
          disableJet.push(item)
        })
      })
      // console.log("props.groupInfo", props.groupInfo);
      // console.log("disableJet", disableJet);
      
        const AllDevicePlaces = props.allDevices
          .map((item) => {
            return item.device_group;
          })
        const _AllParentDevice = props.allDevices
          .filter((device) => {
              return (device.device_type_id == 2);
          })
          .map((item)=>{
              // console.log("MAP____ITEM", item);
              return allParentDevice.push(item)
          })
        const AllJetPlaces = props.allDevices
          .filter((device) => {
              return device.device_type_id == 4;
          })
          .map((item) => {
              return item.capabilities.waterfall_value.map((item2) => {
                return item2.jet_group_name;
          })
        })
        const newJetPlaces = AllJetPlaces.map((item)=>{
          return item.map((item)=>{
            AllDevicePlaces.push(item)
          })
        })
        defaultPlaces.forEach((item)=>{
          AllDevicePlaces.push(item)
        })
        const newDevicePlaces = AllDevicePlaces
          .filter((val, ind, arr) => arr.indexOf(val) === ind)
          .filter((value) => {
            if (value != "") {
              return value;
            }
          })

          setPlaces(newDevicePlaces.reverse())
          

          for (let i = 0; i < 16; i++) {
            allJets.push(
              { checked:checkedJet.indexOf(String(i+1)) != -1,
                disabled:(disableJet.indexOf(String(i+1)) != -1) && !(checkedJet.indexOf(String(i+1)) != -1)
              }
              )
            }

          if(dependence == "on"){ 
            setSelectParent(
            allParentDevice.filter((val)=>{ return val.id == activeJet.jet_relation.jet_dependence.device_id_master}).map((item)=>{return item.id})[0]
          ) 
          setHumidity(activeJet.jet_relation.jet_dependence.value)
        }
    //  console.log("AllJetPlaces", AllJetPlaces);
    //  console.log("props.allDevices", props.allDevices);

        }, []);
       
      const uploadData = (jet) => {
        const data = props.groupInfo.filter((item)=>{
          // console.log(item);
          return item.jet_id != jet.jet_id})
          data.push(jet)
          // console.log("data", data);
          handleSendData(data)
      }  

      const handleSendData = (output) => {
        fetch(SiteUrl + "/devices/" + props.deviceId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
            body: JSON.stringify({capabilities: [
              {
                instance: 'waterfall_value',
                value: output,
              },
            ],}),
            method: "PUT",
          })
            .then((response) => response.json())
            .then((response) => setSavingData(false))
            .then((response) => props.showSettings(false))
            .then((json) => props.fetch());
    };
  
        
    // console.log("newDevicePlaces2", newDevicePlaces);
    // console.log("pauseAfterWork", pauseAfterWork);
    // console.log("checkedJet", checkedJet);
    // console.log("places", places);
    // console.log("addPlace", addPlace);
    // console.log("activeJet", activeJet);
    // console.log("DEFAULT-settings", activeJet);
    return (
        <div className="JetSettings">
          <Grid
          classes={{
            root: "JetSettings__grid"
          }}
          container
          >
            <ListItemText
            classes={{
              root: "JetSettings__textLeft"
            }}
              primary={activeJet.jet_group_name == GROUP_PLACEHOLDER ? <span className="JetSettings__title">Добавить группу</span> : <span className="JetSettings__title">Параметры группы</span>}
            />
            <CloseIcon 
            className="JetSettings__closeIcon"
            onClick={
              ()=>{
                props.showSettings(false)
              }
            }/>
          </Grid>
          <Grid
            container
            classes={{
              root: "JetSettings__grid"
            }}
          >
          <ListItemText
          classes={{
            root: "JetSettings__textLeft"
          }}
              primary={dependence == "off" ? <span>Интервал</span> : <span>Автоуправление</span>}
            />
          <Switch
            color="primary"
            checked={dependence == "off" ? false : true}
            onChange={(val)=>{handleSetDependence(val)}}
          />
          </Grid>
          {dependence == "off" ? (
          <Grid 
            container
            classes={{
              root: "JetSettings__grid"
            }}
            justify="space-around"
          >
            <ListItemText
              classes={{
                root: "JetSettings__textLeft"
              }}
              primary={<span>Пауза после 1 минуты работы (в минутах)</span>}
            />
            <Tabs
              classes={{
                root: classes.TabContainer,
                flexContainer: classes.TabFlexContainer
              }}
              value={pauseAfterWork}
              onChange={handleSetPauseAfterWork}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              >
                {[...Array(7)].map((x, i) => {
                  return (
                  <Tab 
                  key={i}
                  classes={{
                    wrapper: classes.tabWrapper,
                  }}
                  icon={pauseAfterWork == (i+1) ? 
                  <DoneIcon className="JetSettings__icon-p10" /> : null} 
                  label={
                  <span className={pauseAfterWork == (i+1) ? "JetSettings__activeTabVal" : null }>{(i+1)}</span>
                } 
                  value={(i+1)} 
                  className="JetSettings__tabButton"/>
                )
                })}
            </Tabs>
          </Grid>
          ) : (
          <div>
          <Grid
            container
            classes={{
              root: "JetSettings__grid"
            }}
          >
            <FormControl
              variant="outlined"
              classes={{
                root: "JetSettings__select-width"
              }}
            >

              <Select
                labelId="select-dependence-label"
                id="select-dependence"
                open={openSelectParent}
                onClose={handleCloseSelectParent}
                onOpen={handleOpenSelectParent}
                value={selectParent}
                onChange={handleChangeSelectParent}
                
              >
                <MenuItem value="0">Выберите управляющее устройство</MenuItem>
                {allParentDevice.map((item)=>{
                return <MenuItem key={item.id} value={item.id}>{item.device_name}</MenuItem>
                })}
            </Select>
            </FormControl>
          </Grid>
            <Grid
            container
            className="JetSettings__WorkTimeModal-grid"
            justify="space-between"
            alignItems="center"
            classes={{
              root: "JetSettings__grid"
            }}
          >
            <Typography 
              display="block"
            >
              Влажность, %
            </Typography>
            <InputBase
              className="EditValve__WorkTimeModal-numb"
              value={humidity}
              margin="none"
            />
          <Slider
              max={99}
              min={1}
              step={1}
              value={typeof humidity === 'number' ? humidity : 1}
              onChange={ handleSliderChangeHumidity }
              aria-labelledby="WorkTime-ActionWork"
            />
          </Grid>
        </div>
          )}
          <Grid
          container
          justify="center"
            classes={{
              root: "JetSettings__grid"
            }}
            >
            <FormControl
                variant="outlined"
                classes={{
                  root: "JetSettings__select-width"
                }}
              >
              <Select
                open={openGroupName}
                onClose={handleCloseGroupName}
                onOpen={handleOpenGroupName}
                value={groupName}
                onChange={handleChangeGroupName}
                
              >
                {activeJet.jet_group_name == GROUP_PLACEHOLDER ? <MenuItem value={GROUP_PLACEHOLDER}>Выберите расположение форсунок</MenuItem> : null}
                {places.map((item, index)=>{
                return <MenuItem key={index} value={item}>{item}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Button
              onClick={()=>{
                setOpenAddPlace(true)
              }}
              color="primary"
            >
              + или добавьте новое
              </Button>
          </Grid>
          <Grid
          container
          classes={{
            root: "JetSettings__grid2"
          }}
          >
          <ListItemText
            classes={{
              root: "JetSettings__textLeft"
            }}
            primary={<span>Форсунки</span>}
          />
          </Grid>
          <Grid 
          className="JetSettings__checkboxBox"
          container
          classes={{
            root: "JetSettings__grid"
          }}
          >
            {allJets.map((item, index, )=>{
                return <FormControlLabel
                key={index}
                className="JetSettings__checkboxItem"
                value={index+1}
                control={
                <Checkbox
                 color="primary" 
                 defaultChecked={item.checked} 
                 disabled={item.disabled}
                 classes={{
                   root: "JetSettings__checkBox-padding"
                 }}
                 onClick={(e)=>{
                   allJets[index].checked = e.target.checked
                  }}
                 />}
                label={index+1}
                labelPlacement="bottom"
              />
              
            })}
          </Grid>
          <Grid
          container
          classes={{
            root: "JetSettings__grid"
          }}
          >
          {activeJet.jet_group_name != GROUP_PLACEHOLDER ?
           (<Fragment>
             <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={()=>{
                setSavingData(true)
              handleUpdateJetData(allJets)
              }}>
              {savingData ? <CircularProgress color={"inherit"} size={25} /> : "Сохранить"}
            </Button>
            <Button
            className="JetSettings__deleteButton"
            fullWidth
            color="secondary"
            // variant="contained"
            onClick={()=>{
              setOpenConfirmedDelete(true)
              }}>
                Удалить форсунку
            </Button>
           </Fragment>) 
           :
            (<Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={()=>{
              handleUpdateJetData(allJets)
              }}>
              Добавить
            </Button>)
            }
            
          </Grid>
          <Dialog 
            open={openAddPlace} 
            onClose={() => {setOpenAddPlace(false)}} 
            aria-labelledby="form-dialog-title"
          >

            <DialogTitle id="form-dialog-title">Добавить расположение</DialogTitle>
            <DialogContent>
              <Input 
                placeholder="Комната или группа"
                value={addPlace}
                onChange={(event) => {setAddPlace(event.target.value)}}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={()=> {
                  setOpenAddPlace(false)
                  setAddPlace('')
                    }
                  }
                color="primary">
                  Отмена
              </Button>
              <Button onClick={
                () => {
                  handleSetAddPlace(addPlace.trim())
                  setOpenAddPlace(false)
                  setAddPlace('')
                  }
                } 
                color="primary"
              >
                Добавить
              </Button> 
            </DialogActions>
          </Dialog>
          <Dialog 
            open={openConfirmedDelete} 
            onClose={() => {setOpenConfirmedDelete(false)}} 
          >

            <DialogTitle id="form-dialog-title">Удалить форсунку?</DialogTitle>
            <DialogContent>
              <Typography>
                Вы уверены, что хотите удалить форсунку?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={()=> {
                  setOpenConfirmedDelete(false)
                    }
                  }
                color="primary">
                  Отмена
              </Button>
              <Button onClick={
                () => {
                  handleDeleteJet()
                  setOpenConfirmedDelete(false)
                  }
                } 
                color="primary"
              >
                Подтвердить
              </Button> 
            </DialogActions>
          </Dialog>
          <Snackbar
          className="JetSettings__snackbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            // open={snackBar.show}
            open={snackBar.show}
            autoHideDuration={3000}
            onClose={()=>{setSnackBar({...snackBar, show:false})}}
            message={snackBar.message}
            onClick={()=>{setSnackBar({...snackBar, show:false})}}
      />
        </div>
    )
}

JetSettings.defaultProps = {

      data: {
        jet_group_name: GROUP_PLACEHOLDER,
        jet_capabilities: {
          jet_manual: 1,
          jet_numbers: ""
        },
        jet_on_off: "on",
        jet_relation: {
          jet_on_off_dependence : "off"
        }  
      }
    
}

export default JetSettings
